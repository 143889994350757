export const regularFlow = [
    {
        stepId: 1,
        path: '/',
        btnText: 'next'
    },
    {
        stepId: 2,
        path: '/questions',
        btnText: 'next'
    },
    {
        stepId: 3,
        path: '/date-select',
        btnText: 'next'
    },
    {
        stepId: 4,
        path: '/info',
        btnText: 'next'
    },
    {
        stepId: 5,
        path: '/confirm',
        btnText: 'sendBooking'
    },
    {
        stepId: 6,
        path: '/complete',
    }
]

export const contactFlow = [
    {
        stepId: 1,
        path: '/',
        btnText: 'next'
    },
    {
        stepId: 2,
        path: '/questions',
        btnText: 'next'
    },
    {
        stepId: 3,
        path: '/date-select',
        btnText: 'next'
    },
    {
        stepId: 4,
        path: '/info',
        btnText: 'next'
    },
    {
        stepId: 5,
        path: '/contactForm',
        btnText: 'sendBooking'
    },
    {
        stepId: 6,
        path: '/complete',
    }
]

export const simpleFlow = [
    {
        stepId: 1,
        path: '/',
        btnText: 'next'
    },
    {
        stepId: 2,
        path: '/questions',
        btnText: 'next'
    },
    {
        stepId: 3,
        path: '/info-simple',
        btnText: 'getContacted'
    }
]

export const shortFlow = [
    {
        stepId: 1,
        path: '/',
        btnText: 'next'
    },
    {
        stepId: 2,
        path: '/questions',
        btnText: 'next'
    },
    {
        stepId: 3,
        path: '/date-select',
        btnText: 'next'
    },
    {
        stepId: 4,
        path: '/info',
        btnText: 'sendBooking'
    },
    {
        stepId: 5,
        path: '/complete',
    }
]
