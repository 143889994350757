import { FC, useEffect, useState } from "react";
import { DropdownEl } from "../Components/Dropdown/Dropdown";
import LoaderEl from "../Components/Loader/loader";
import SectionWrapEl from "../Components/Section-wrapper/Section-wrapper";
import { useFormState } from "../FormStateContext";
import {
  clearClinic,
  saveClinic,
  searchClinics,
} from "../Store/actions/clinics";
import {
  setButtonDisabled,
  setStepLength,
  setSubmitState,
} from "../Store/actions/flow";
import { clearPatientInitialState } from "../Store/actions/patient";
import {
  clearTimeslots,
  clearTimeslotsInitialState,
} from "../Store/actions/timeSlots";
import { connectedStore } from "../utils/connectedStore";
import { translate } from "../utils/translationUtils";
import "./pages.scss";

const Clinics: FC<any> = ({ store, dispatch }) => {
  const [hasError, setHasError] = useState(false);

  const { clinics, selectedTreatment, loading } = store.clinics;
  const { state, dispatch: formDispatch } = useFormState();
  const storedCampaignId = sessionStorage.getItem("campaignId");

  const { submitState, campaignId } = store.flow;
  clinics.sort((a: { label: any }, b: { label: string }) =>
    a.label.localeCompare(b.label)
  );

  useEffect(() => {
    if (!selectedTreatment) {
      dispatch(clearClinic(null));
      dispatch(clearTimeslots(null));
      dispatch(searchClinics(store.flow.campaignId));
    }
  }, []);

  useEffect(() => {
    if (loading == false) {
      if (clinics.length === 1) {
        dispatch(saveClinic(clinics[0]));
        dispatch(setSubmitState("complete"));
      } else if (store.flow.clinicId) {
        const found = clinics.find((clinic: any) => {
          return clinic.clinicId == store.flow.clinicId;
        });

        dispatch(saveClinic(found));
        dispatch(setSubmitState("complete"));
      }
    }
  }, [loading]);

  useEffect(() => {
    if (submitState === "check") {
      setHasError(false);

      if (selectedTreatment?.value) {
        if (window.location.pathname === "/") {
          const selectedClinic = selectedTreatment.label;

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "next_step",
            step_name: "choose clinic",
            campaignId: storedCampaignId,
            current_step: "1",
            total_steps: "5",
            clinic: selectedClinic || "Unknown Clinic",
          });
        }

        dispatch(setSubmitState("complete"));
      } else {
        dispatch(setSubmitState("error"));
        setHasError(true);
      }
    }
  }, [submitState]);

  useEffect(() => {
    dispatch(
      setButtonDisabled(!!Array.isArray(selectedTreatment) ? true : false)
    );
  }, [selectedTreatment]);

  const handleClinicChange = (clinic: any) => {
    dispatch(saveClinic(clinic));
    dispatch(clearTimeslotsInitialState());
    dispatch(clearPatientInitialState());
    dispatch(setStepLength(0));
    formDispatch({ type: "SET_VISITED_STEPS", payload: [0] });
    formDispatch({ type: "SET_CPR", payload: "" });
    formDispatch({ type: "SET_FIRST_NAME", payload: "" });
    formDispatch({ type: "SET_LAST_NAME", payload: "" });
    formDispatch({ type: "SET_STREET_NAME", payload: "" });
    formDispatch({ type: "SET_STREET_NUMBER", payload: "" });
    formDispatch({ type: "SET_POST_NUMBER", payload: "" });
    formDispatch({ type: "SET_CITY", payload: "" });
    formDispatch({ type: "SET_MOBILE", payload: "" });
    formDispatch({ type: "SET_EMAIL", payload: "" });
    formDispatch({ type: "SET_CPR", payload: "" });
    formDispatch({ type: "SET_POLICY_1", payload: false });
    formDispatch({ type: "SET_POLICY_2", payload: false });
  };

  return (
    <div className="page--clinics">
      <SectionWrapEl>
        {loading && (
          <div className="page--date-select--loader-wrap">
            <LoaderEl />
          </div>
        )}
        <DropdownEl
          error={hasError}
          placeholder={translate("select_clinic", true)}
          dropdownList={clinics}
          setDropdownItem={handleClinicChange}
          selectedItem={selectedTreatment}
        />
      </SectionWrapEl>
    </div>
  );
};

export default connectedStore(Clinics);
