import styled from 'styled-components'
import { Colors } from '../../colors'

export const SectionWrap = styled.div`
    border: 1px solid ${Colors.border};
    padding: 1rem 1rem;
    margin: 1rem;
    border-radius: 10px;
    background-color: rgb(249, 249, 249);
    font-size:20px;
`

