import { FC, useEffect, useState } from "react";
import InputEl from "../Components/Input/Input";
import SectionWrapEl from "../Components/Section-wrapper/Section-wrapper";
import { searchPatient, setPatient, setEmailState, setPhoneState } from "../Store/actions/patient";
import { setButtonDisabled, setCreateRealAppointment, setSubmitState } from "../Store/actions/flow";
import { connectedStore } from "../utils/connectedStore";
import { translate } from "../utils/translationUtils";
import dayjs from "dayjs";
import LoaderEl from "../Components/Loader/loader";
import { Checkbox } from "@material-ui/core";
import cprLogo from '../assets/cpr.svg';
import cprLogoSvg from '../assets/cprLogo.svg';


const ContactForm: FC<any> = ({ store, dispatch }) => {
  const { submitState, campaignId } = store.flow;
  const { fullName, patientFound, cprForm } = store.patient;
  const { selectedTreatment } = store.clinics;
  const { selectedTime, selectedDate, selectedTimeslot, selectedPractitioner, selectedAppointmentType } = store.timeSlots;

  const [checkingCPR, setCheckingCPR] = useState(true); 
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [streetName, setStreetName] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [postNumber, setPostNumber] = useState('');
  const [city, setCity] = useState('');
  const [cpr, setCpr] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [policy1, setPolicy1] = useState(false);
  const [policy2, setPolicy2] = useState(false);

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

  const cprRegex = /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])\d{2}?\s[-]?\s\d{4}$/gm

  useEffect(() => {
    setCpr(cprForm);
  }, []);

  useEffect(() => {
    let disableButton = false;
    if(checkingCPR && (cpr === '' || !cpr.slice(0, 13).match(cprRegex))) {
        disableButton = true;
    }
    if(!checkingCPR && (!policy1 || !policy2 || firstName === '' || lastName === '' || streetName === '' || streetNumber === '' || postNumber === '' || postNumber.length !== 4 || city === '' || cpr === '' || mobile === '' || mobile.length !== 8 || email === '')) {
      disableButton = true;
    }
    if(patientFound) {
      disableButton = false;
    }
    dispatch(setButtonDisabled(disableButton));
  }, [checkingCPR, cpr, policy1, policy2, firstName, lastName, streetName, streetNumber, postNumber, city, cpr, mobile, email]); 

  useEffect(() => {

    if(submitState === 'check') {
  
          if(!policy1 || !policy2 || firstName === '' || lastName === '' || streetName === '' || streetNumber === '' || postNumber === '' || postNumber.length !== 4  || city === '' || cpr === '' || mobile === '' || mobile.length !== 8 || email === '' || !email.match(emailRegex)) {
            setHasError(true);
            dispatch(setSubmitState('error'));
            return;
            
          }
          else {
          const patientObj = {
            FirstName: firstName,
            LastName: lastName,
            Phone: mobile,
            StreetName: streetName,
            StreetNumber: streetNumber,
            City: city,
            ZipCode: postNumber,
            Email: email,
            Cpr: cpr.replace(/ /g, ''),
            campaign_id: campaignId
          }
          dispatch(setPatient(selectedTreatment.clinicId, patientObj)).then(() => {
            createRealAppointment();
          });
          dispatch(setSubmitState('complete'));
          dispatch(setPhoneState(mobile));
        }
    }
    
  }, [submitState]);

  const setTransformedCpr = (e : any) => {
    
    if(e.length < 13) {
      setCpr(e.replace(/[^\dA-Z]/g, '').replace(/(.{6})/g, '$1 - ').trim());
    }
    else {
      setCpr(e.slice(0, 13));
    }

  }

  const createRealAppointment = () => {
    const appointmentObj = {
      timeslot_id: selectedTimeslot, 
      dentist_id: selectedPractitioner,
      patient_id: cpr.replace(/ /g, '')
    }
    dispatch(setCreateRealAppointment(selectedTreatment.value, appointmentObj));
  }


  return (
    <div>
      {loading &&
        <div className="page--date-select--loader-wrap">
          <LoaderEl/>
        </div>
      }
     
               
            <SectionWrapEl>
         
              <InputEl autofucus error={hasError && firstName === ''} label={'firstName'} placeholder={'firstName'} value={firstName} setValue={setFirstName} required/>
              <InputEl error={hasError && lastName === ''} label={'lastName'} placeholder={'lastName'} value={lastName} setValue={setLastName} required/>
              
              <InputEl error={hasError && streetName === ''} label={'address'} placeholder={'streetName'} value={streetName} setValue={setStreetName} required/>
              <InputEl type={'tel'} error={hasError && streetNumber === ''} placeholder={'number'} value={streetNumber} setValue={setStreetNumber}  required/>
              <InputEl type={'tel'} error={hasError && (postNumber === '' || postNumber.length !== 4)} placeholder={'postNumber'} value={postNumber} length={4} setValue={setPostNumber} required/>
              <InputEl  error={hasError && city === ''} placeholder={'city'} value={city} setValue={setCity} required/>

              <InputEl type={'tel'} error={hasError && (cpr === '' || !cpr.match(cprRegex))} label={'cprNumber'} placeholder={'XXXXXX - XXXX'} value={cpr} setValue={setTransformedCpr} required/>
              <InputEl type={'tel'} error={hasError && (mobile === '' || mobile.length !== 8)} label={'phoneNumber'} placeholder={'phoneNumber'} value={mobile} length={8} setValue={setMobile} required/>
              <InputEl type={'email'} error={hasError && (email === '' || !email.match(emailRegex))} label={'email'} placeholder={'email'} value={email}  setValue={setEmail} required/>
            
              <div className="page--info-simple--policy">
              <Checkbox  onClick={() => setPolicy1(!policy1)} value={policy1}/>
              <p className={(hasError && !policy1) ? 'page--info-simple--policy--error' : ''}>
                {translate('policy_1', true)}
                <a href="https://www.godtsmil.dk/cookie-politik-godtsmil-dk/" target='_blank'> {translate('link', true)}</a>
              </p>
            </div>

            <div className="page--info-simple--policy">
          
              <Checkbox  onClick={() => setPolicy2(!policy2)} value={policy2}/>
              <p className={(hasError && !policy2) ? 'page--info-simple--policy--error' : ''}>
                {translate('policy_2', true)}
                <a href="https://www.godtsmil.dk/velkomst-info/" target='_blank'> {translate('link', true)}</a>
              </p>
            
            </div>
           
            </SectionWrapEl>
       
    
    </div>
  );
}

export default connectedStore(ContactForm);
