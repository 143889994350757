import { FC } from "react";
import { PrimaryBtn } from "../Components/Button/styles";
import SectionWrapEl from "../Components/Section-wrapper/Section-wrapper";
import { setCampaignId } from "../Store/actions/flow";
import { connectedStore } from "../utils/connectedStore";
import { translate } from "../utils/translationUtils";

const Error: FC<any> = ({ store, dispatch }) => {
    const { campaignId } = store.flow;

    const resetForm = () => {
        sessionStorage.removeItem('state');
        dispatch(setCampaignId(campaignId));
        window.location.href = '/';
    };

    return (
        <div className="page--error">
            <SectionWrapEl>
                <h2>{translate('error_headline')}</h2>
                <PrimaryBtn onClick={resetForm}>{translate('error_button')}</PrimaryBtn>
            </SectionWrapEl>   
        </div>
    );
}

export default connectedStore(Error);
