import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import { applyMiddleware, compose, createStore } from "redux";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";
import { getReducers } from "./reducers";

export const history = createBrowserHistory();

export default function configureStore(history: any, initialState: any) {
  const enhancers = [];
  const middleware = [thunk, promise, routerMiddleware(history)];

  if (process.env.NODE_ENV === "development") {
    const devToolsExtension = (window as any)
      .__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

    if (typeof devToolsExtension === "function") {
      enhancers.push(devToolsExtension());
    }
  }

  const rootReducer = getReducers(history);
  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );
  return createStore(rootReducer, initialState, composedEnhancers);
}

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
  } catch {
    // ignore write errors
  }
};
