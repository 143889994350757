import styled from "styled-components";
import { Colors } from "../../colors";

const DropdownWrap = styled.div`
    position: relative;
    margin: 1rem 0;
    position: relative;
    height: 1.5rem
    margin-bottom: 30px;
`;

const DropdownPickerField = styled.div`
  background-color: ${Colors.white};
  color: ${Colors.text};
  padding: 0.5rem 0;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  z-index: 9;
  position: absolute;
  width: 100%;
  transition: 0.2s ease all;
  max-height: ${({ opened }) => (opened ? "none" : "1.5rem")};
  min-height: ${({ opened }) => (opened ? "100px" : "1.5rem")};
  overflow: ${({ opened }) => (opened ? "initial" : "hidden")};
  box-shadow: 0px 5px 8px 0px rgba(34, 60, 80, 0.2);
  border: ${({ hasError }) => (hasError ? "1px solid red" : "none")};
`;

const DropdownPickerFieldIos = styled.select`
  background-color: ${({ disabled }) =>
    disabled ? Colors.text_light : Colors.white};
  color: ${Colors.text};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  font-size: 20px;
  border-radius: 4px;
  z-index: 9;
  width: 100%;
  transition: 0.2s ease all;
  max-height: ${({ opened }) => (opened ? "none" : "3rem")};
  min-height: ${({ opened }) => (opened ? "100px" : "1.5rem")};
  overflow: ${({ opened }) => (opened ? "initial" : "hidden")};
  box-shadow: 0px 5px 8px 0px rgba(34, 60, 80, 0.2);
  border: ${({ hasError }) => (hasError ? "1px solid red" : "none")};
  height: 2rem;
`;

const DropdownPickerList = styled.div`
  border-top: ${({ opened }) =>
    opened ? "1px solid" + Colors.text_light : "0"};
  opacity: ${({ opened }) => (opened ? "1" : "0")};
  margin: 1rem 0;
  padding-top: 0.5rem;
  transition: 0.5s ease all;
`;

const DropdownPickerPlaceholder = styled.div`
  color: ${Colors.text_light};
`;

const DropdownPickerItem = styled.div`
  text-align: start;
  color: ${Colors.text};
  padding: 0.5rem 1rem;
  &:hover {
    background-color: ${Colors.primary};
    color: ${Colors.white};
  }
`;

export {
  DropdownPickerField,
  DropdownPickerFieldIos,
  DropdownPickerItem,
  DropdownPickerList,
  DropdownPickerPlaceholder,
  DropdownWrap,
};
