import React, { FC } from "react";
import { translate } from "../../utils/translationUtils";
import {
    Input,
    InputLabel,
    InputWrapper,
    InputRequiredTag
} from './styles'

const InputEl: FC<any> = ({autofucus=false, label, placeholder, value, setValue, required, number=false, error, length, type}) => {
    return (
        <InputWrapper>
            {label && <InputLabel>{translate(label, true)}{required ? <><InputRequiredTag>*</InputRequiredTag>:</> : ':'}</InputLabel>}
            <Input
                hasError={error}
                placeholder={translate(placeholder, true)}
                value={value}
                onChange={(e: any) => setValue(e.target.value)}
                type={type}
                pattern={number ? '[0-9]*' : ''}
                autoFocus={autofucus}
                maxLength={length}
               
            ></Input>
        </InputWrapper>
    );
  }
  
  export default InputEl;
  