import { FC, useEffect, useRef, useState } from "react";
import cprLogoSvg from "../assets/cprLogo.svg";
import InputEl from "../Components/Input/Input";
import LoaderEl from "../Components/Loader/loader";
import SectionWrapEl from "../Components/Section-wrapper/Section-wrapper";
import { useFormState } from "../FormStateContext";
import {
  setButtonDisabled,
  setFlowType,
  setSubmitState,
} from "../Store/actions/flow";
import { searchPatient, setCPRState } from "../Store/actions/patient";
import { connectedStore } from "../utils/connectedStore";

const Info: FC<any> = ({ store, dispatch }) => {
  const { submitState, campaignId } = store.flow;
  const { fullName, patientFound } = store.patient;
  const { selectedTreatment } = store.clinics;
  const {
    selectedTime,
    selectedDate,
    selectedTimeslot,
    selectedPractitioner,
    selectedAppointmentType,
  } = store.timeSlots;

  const storedCampaignId = sessionStorage.getItem("campaignId");

  const { state, dispatch: formDispatch } = useFormState();

  const [checkingCPR, setCheckingCPR] = useState(true);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [streetName, setStreetName] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [postNumber, setPostNumber] = useState("");
  const [city, setCity] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [policy1, setPolicy1] = useState(false);
  const [policy2, setPolicy2] = useState(false);
  const [cpr, setCpr] = useState(state.infoPage.cpr || "");

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const cprRegex =
    /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])\d{2}?\s[-]?\s\d{4}$/gm;
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (state.infoPage.cpr) {
      setCpr(state.infoPage.cpr);
    }
  }, [state.infoPage.cpr]);

  useEffect(() => {
    formDispatch({ type: "SET_CPR", payload: cpr });
  }, [cpr, formDispatch]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    let disableButton = false;
    if (checkingCPR && (cpr === "" || !cpr.slice(0, 13).match(cprRegex))) {
      disableButton = true;
    }
    if (
      !checkingCPR &&
      (!policy1 ||
        !policy2 ||
        firstName === "" ||
        lastName === "" ||
        streetName === "" ||
        streetNumber === "" ||
        postNumber === "" ||
        postNumber.length !== 4 ||
        city === "" ||
        cpr === "" ||
        mobile === "" ||
        mobile.length !== 8 ||
        email === "")
    ) {
      disableButton = true;
    }
    if (patientFound) {
      disableButton = false;
    }
    dispatch(setButtonDisabled(disableButton));
  }, [
    checkingCPR,
    cpr,
    policy1,
    policy2,
    firstName,
    lastName,
    streetName,
    streetNumber,
    postNumber,
    city,
    cpr,
    mobile,
    email,
  ]);

  useEffect(() => {
    if (submitState === "check") {
      if (window.location.pathname === "/info") {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "next_step",
          step_name: "info",
          campaignId: storedCampaignId || "default_campaign",
          current_step: "4",
          total_steps: "5",
          clinic: selectedTreatment?.label || "Unknown Clinic",
          questions: store.flow.questions.map((q: any) => ({
            question: q.question,
            answer: q.answer === 1 ? "ja" : "nej",
          })),
          chosen_date: selectedDate
            ? selectedDate.split("-").reverse().join("")
            : null,
          chosen_time: selectedTime?.value || null,
        });
      }
      if (checkingCPR) {
        if (cpr === "" || !cpr.slice(0, 13).match(cprRegex)) {
          setHasError(true);
          dispatch(setSubmitState("error"));
          return;
        }
        setHasError(false);
        setLoading(true);
        dispatch(
          searchPatient(selectedTreatment.clinicId, cpr, storedCampaignId)
        ).then(() => {
          setLoading(false);
          setCheckingCPR(false);
          dispatch(setSubmitState("confirm"));
          return;
        });
      }
    } else if (submitState === "confirm") {
      if (patientFound) {
        dispatch(setCPRState(cpr));
        dispatch(setSubmitState("complete"));
      } else {
        dispatch(setFlowType("contact"));
        dispatch(setCPRState(cpr));

        dispatch(setSubmitState("complete"));
      }
    }
  }, [submitState]);

  const setTransformedCpr = (input: string) => {
    const numericInput = input.replace(/[^\d]/g, "");

    if (numericInput.length <= 6) {
      setCpr(numericInput);
    } else {
      const formattedCpr = `${numericInput.slice(0, 6)} - ${numericInput.slice(
        6,
        10
      )}`;
      setCpr(formattedCpr);
    }
  };

  return (
    <div>
      {loading && (
        <div className="page--date-select--loader-wrap">
          <LoaderEl />
        </div>
      )}
      <SectionWrapEl>
        <div className="page--info--cpr-wrap">
          <div className="page--info--cpr-wrap--input">
            <InputEl
              ref={inputRef}
              type={"tel"}
              error={hasError && (cpr === "" || !cpr.match(cprRegex))}
              label={"cprNumber"}
              placeholder={"XXXXXX - XXXX"}
              value={cpr}
              setValue={setTransformedCpr}
              required
            />
          </div>
        </div>
        <div className="page--info-simple text-text_blue">
          <div className="page--info-simple--cpr-wrapper">
            <img width="45px" height="45px" src={cprLogoSvg} />
            <p className="page--info-simple--cpr-2"> Du kan trygt indtaste </p>
            <p className="page--info-simple--cpr-2">dit CPR-nummer </p>
          </div>
          <p> Vi krypterer og behandler det med maksimal datasikkerhed.</p>
        </div>
      </SectionWrapEl>
    </div>
  );
};

export default connectedStore(Info);
