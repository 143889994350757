import dayjs from "dayjs";
import { FC, useEffect, useRef, useState } from "react";
import { Colors } from "../colors";
import DatePickerEl from "../Components/Date-Picker/Date-Picker";
import DividerEl from "../Components/Divider/Divider";
import { DropdownEl } from "../Components/Dropdown/Dropdown";
import LoaderEl from "../Components/Loader/loader";
import SectionWrapEl from "../Components/Section-wrapper/Section-wrapper";
import { useFormState } from "../FormStateContext";
import {
  setButtonDisabled,
  setCreateDummyAppointment,
  setSubmitState,
} from "../Store/actions/flow";
import {
  getTimeslots,
  setSelectedAppointmentLength,
  setSelectedAppointmentType,
  setSelectedDate,
  setSelectedPractitioner,
  setSelectedTime,
  setTimeslot,
} from "../Store/actions/timeSlots";
import { connectedStore } from "../utils/connectedStore";
import { translate } from "../utils/translationUtils";

const DateSelect: FC<any> = ({ store, dispatch }) => {
  const {
    timeslots,
    selectedDate,
    selectedTime,
    selectedTimeslot,
    selectedPractitioner,
    loading,
    selectedAppointmentLength,
  } = store.timeSlots;
  const { submitState, questions, campaignId, answers } = store.flow;
  const { selectedTreatment } = store.clinics;
  const storedCampaignId = sessionStorage.getItem("campaignId");

  const { state, dispatch: formDispatch } = useFormState(); // Use context
  const [currentPage, setCurrentPage] = useState(1);
  const [hasError, setHasError] = useState(false);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [isCreatingDummyAppointment, setIsCreatingDummyAppointment] =
    useState(false);
  const [appointmentLength, setAppointmentLength] = useState(0);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [defaultMonth, setDefaultMonth] = useState(new Date());
  const myRef = useRef(null);

  useEffect(() => {
    if (selectedDate) {
      const day = dayjs(selectedDate).format("YYYY-MM-DD");
      if (timeslots[day]) {
        let timeSlots = Object.keys(timeslots[day]).map((time) => ({
          value: time,
          label: time,
        }));

        if (
          selectedTime &&
          selectedTime.value &&
          !timeSlots.some((slot) => slot.value === selectedTime.value)
        ) {
          timeSlots = [
            { value: selectedTime.value, label: selectedTime.value },
            ...timeSlots,
          ];
        }
        // @ts-ignore
        setAvailableTimeSlots(timeSlots);
      } else {
        setAvailableTimeSlots([]);
      }
    }
  }, [selectedDate, selectedTime, timeslots]);

  useEffect(() => {
    dispatch(getTimeslots(selectedTreatment.value, currentPage));
  }, [currentPage]);

  useEffect(() => {
    if (submitState === "check") {
      setHasError(false);
      if (!selectedTime || !selectedDate) {
        setHasError(true);
        dispatch(setSubmitState("error"));
        return;
      }

      if (window.location.pathname === "/date-select") {
        const formattedDate = dayjs(selectedDate).format("DDMMYYYY");

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "next_step",
          step_name: "date-select",
          campaignId: storedCampaignId,
          current_step: "3",
          total_steps: "5",
          clinic: selectedTreatment?.label || "Unknown Clinic",
          questions: questions.map((question: any, index: number) => ({
            question: question.question,
            answer: answers[index] === 1 ? "ja" : "nej",
          })),
          chosen_date: formattedDate,
          chosen_time: selectedTime.value,
        });
      }

      setIsCreatingDummyAppointment(true);
      dispatch(
        setCreateDummyAppointment(selectedTreatment.value, {
          timeslot_id: selectedTimeslot,
          dentist_id: selectedPractitioner,
        })
      ).then(() => {
        setIsCreatingDummyAppointment(false);
        dispatch(setSubmitState("complete"));
      });
    }
  }, [submitState]);

  useEffect(() => {
    let disableButton = true;
    if (
      selectedDate &&
      selectedTime &&
      selectedTimeslot &&
      selectedTime.value
    ) {
      disableButton = false;
    }
    dispatch(setButtonDisabled(disableButton));
  }, [selectedDate, selectedTime, selectedTimeslot]);

  const handleDateChange = (date: any) => {
    setAvailableTimeSlots([]);
    dispatch(setSelectedDate(null));
    const time: any = {};
    time.value = "";
    time.label = "";
    dispatch(setSelectedTime(time));
    const hasTimeSlots = !!Object.keys(timeslots).length;

    if (hasTimeSlots) {
      const element = document.getElementById("section-1");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      const day = dayjs(date).format("YYYY-MM-DD");
      const slots = timeslots[day];
      dispatch(setSelectedDate(dayjs(date).format("YYYY-MM-DD")));

      if (slots) {
        const element = document.getElementById("section-1");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
        let timeSlots = Object.keys(timeslots[day]) as any;
        setAvailableTimeSlots(
          timeSlots.map((time: any) => {
            return { value: time, label: time };
          })
        );
      }
    }
  };

  const disableDates = (date: string) => {
    const day = dayjs(date).format("YYYY-MM-DD");
    const isDisabled = !timeslots[day];
    return isDisabled;
  };

  const findFirstAvailableDate = () => {
    const sortedDates = Object.keys(timeslots).sort((a, b) =>
      dayjs(a).isBefore(dayjs(b)) ? -1 : 1
    );
    return sortedDates.find((date) => timeslots[date]);
  };

  const selectTimeAndPractitioner = (time: any) => {
    const activeTimeItem = timeslots[selectedDate][time.value].items[0];
    const dentistListLength = activeTimeItem.Dentists.length;
    dispatch(setSelectedAppointmentType(activeTimeItem.Name));
    dispatch(setSelectedAppointmentLength(activeTimeItem.Duration));

    if (dentistListLength > 1) {
      const getPractitioner = Math.floor(Math.random() * dentistListLength) + 1;
      dispatch(
        setSelectedPractitioner(activeTimeItem.Dentists[getPractitioner].ID)
      );
    } else {
      dispatch(setSelectedPractitioner(activeTimeItem.Dentists[0].ID));
    }

    const element = document.getElementById("section-1");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    dispatch(setTimeslot(activeTimeItem.ID));
    dispatch(setSelectedTime(time));
  };

  const handleMonthChange = (dateObj: any) => {
    dispatch(setSelectedDate(null));
    setAvailableTimeSlots([]);
    const time: any = {};
    time.value = "";
    time.label = "";
    dispatch(setSelectedTime(time));

    if (currentDate < dateObj.$d) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage - 1);
    }
    setCurrentDate(dateObj);
  };

  return (
    <SectionWrapEl>
      <p className="page--date-select--selected_date">
        {translate("selected_date", true)}
      </p>
      {isCreatingDummyAppointment ||
        (loading && (
          <div className="page--date-select--loader-wrap">
            <div className="page--date-select--loader-text">
              Vi henter ledige tider <br /> -det kan tage et øjeblik
            </div>
            <LoaderEl />
          </div>
        ))}
      <DatePickerEl
        selectedDate={selectedDate}
        disableDates={disableDates}
        handleDateChange={handleDateChange}
        handleMonthChange={handleMonthChange}
        firstAvailableDate={findFirstAvailableDate()}
      ></DatePickerEl>

      <>
        <DividerEl />

        <div className="flex flex-col items-center gap-3 py-4">
          <div className="space-y-2">
            <div className="flex items-center gap-5">
              <div className="w-5 h-5 rounded-full bg-[#FFF2E0] border-[1px] border-text_blue" />
              <p className="text-base text-text_blue font-semibold">
                Dage med ledige tider
              </p>
            </div>

            <div className="flex items-center gap-5">
              <div className="w-5 h-5 rounded-full bg-[#FF9D00]  border-[1px] border-text_blue" />
              <p className="text-base text-text_blue font-semibold">
                Din valgte dato
              </p>
            </div>
          </div>
        </div>

        {selectedDate && selectedTime.value && (
          <div className="flex justify-center items-center gap-2">
            <span className="text-text_blue font-bold">
              {selectedDate &&
                dayjs(selectedDate).locale("da").format("DD. MMM YYYY")}
            </span>
            <span className="text-text_blue font-bold">-</span>
            <span className="text-text_blue font-bold">
              {selectedTime?.value}
            </span>
          </div>
        )}

        <DropdownEl
          error={hasError && selectedTime === ""}
          placeholder={translate("select_time", true)}
          dropdownList={availableTimeSlots}
          selectedItem={selectedTime}
          setDropdownItem={selectTimeAndPractitioner}
          backgroundColor={Colors.orange_light}
        />

        <div className="text-text_blue">
          {selectedTime.value && (
            <>
              <p className="text-base font-semibold">
                {translate("set_aside", true)}
              </p>
              <b className="font-bold">
                {selectedAppointmentLength} {translate("time_aside", true)}
              </b>
            </>
          )}
        </div>
      </>

      <div id="section-1"></div>
    </SectionWrapEl>
  );
};

export default connectedStore(DateSelect);
