import { FC, useEffect, useState } from "react";
import InputEl from "../Components/Input/Input";
import SectionWrapEl from "../Components/Section-wrapper/Section-wrapper";
import { searchPatient, setPatientContact } from "../Store/actions/patient";
import { setSubmitState } from "../Store/actions/flow";
import { connectedStore } from "../utils/connectedStore";
import { translate } from "../utils/translationUtils";
import { Checkbox } from "@material-ui/core";

const InfoSimple: FC<any> = ({ store, dispatch }) => {
  const { submitState } = store.flow;
  const { selectedTreatment } = store.clinics;

  const [hasError, setHasError] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [policy1, setPolicy1] = useState(false);
  const [policy2, setPolicy2] = useState(false);

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

  useEffect(() => {
    
    if(submitState === 'check') {
      window.location.href='https://kampagne.godtsmil.dk/online-booking-spm';
        if(!policy1 || !policy2 || firstName === '' || mobile === '' || email === '' || mobile.length !== 8 || !email.match(emailRegex)) {
          setHasError(true);
          dispatch(setSubmitState('error'));
          return;
        }
        const contactObj = {
          name: firstName,
          phone: mobile,
          email: email
        }
        dispatch(setPatientContact(selectedTreatment.clinicId, contactObj));
        dispatch(setSubmitState('complete'));
    } 
  }, [submitState]);




  return (
    <div className="page--info-simple">
       <div className="page--info-simple--text">
        <b>Ud fra dine svar har vi behov for at få besvaret et par yderligere spørgsmål inden,
       
du kan booke en tid.</b>
        <p>Vi vil derfor gerne ringe dig op.

(senest næste hverdag mellem kl. 8 – 16)</p>  
</div>    
        {/* <SectionWrapEl>
            <InputEl error={hasError && firstName === ''} label={'firstName'} placeholder={'firstName'} value={firstName} setValue={setFirstName} required/>
            <InputEl error={hasError && (mobile === '' || mobile.length !== 8)} label={'phoneNumber'} placeholder={'phoneNumber'} value={mobile} setValue={setMobile} required/>
            <InputEl error={hasError && (email === '' || !email.match(emailRegex))} label={'email'} placeholder={'email'} value={email} setValue={setEmail} required/>   

            <div className="page--info-simple--policy" onClick={() => setPolicy1(!policy1)}>
              <Checkbox value={policy1}/>
              <p className={(hasError && !policy1) ? 'page--info-simple--policy--error' : ''}>
                {translate('policy_1', true)}
                <a href="https://www.godtsmil.dk/cookie-politik-godtsmil-dk/" target='_blank'> {translate('link', true)}</a>
              </p>
            </div>

            <div className="page--info-simple--policy" onClick={() => setPolicy2(!policy2)}>
              <Checkbox value={policy2}/>
              <p className={(hasError && !policy2) ? 'page--info-simple--policy--error' : ''}>
                {translate('policy_2', true)}
                <a href="https://www.godtsmil.dk/velkomst-info/" target='_blank'> {translate('link', true)}</a>
              </p>
            </div>

        </SectionWrapEl>    */}
    </div>
  );
}

export default connectedStore(InfoSimple);
