import {
    Divider
} from './styles'

const DividerEl = () => {
    return (
        <Divider></Divider>
    );
  }
  
export default DividerEl;
  