import { FC } from 'react';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import DateJsUtils from '@date-io/dayjs';
import { isMobile } from 'react-device-detect';
import { datePickerTheme } from './styles.js';
import da from 'dayjs/locale/da';

export const DatePickerEl: FC<any> = ({
    selectedDate,
    disableDates,
    handleDateChange,
    handleMonthChange
}) => (
    <ThemeProvider theme={datePickerTheme(selectedDate, isMobile)}>
        <MuiPickersUtilsProvider locale={da} utils={DateJsUtils}>
            <KeyboardDatePicker
                variant="static"
                format='YYYY-MM-DD' 
                value={selectedDate}
                onChange={handleDateChange}
                shouldDisableDate={disableDates}
                disableToolbar
                disablePast
                onMonthChange={handleMonthChange}
            />
        </MuiPickersUtilsProvider>
    </ThemeProvider>
)

export default DatePickerEl;
