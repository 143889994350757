import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import DividerEl from "../Components/Divider/Divider";
import SectionWrapEl from "../Components/Section-wrapper/Section-wrapper";
import { connectedStore } from "../utils/connectedStore";
import { translate } from "../utils/translationUtils";

const Complete: FC<any> = ({ store, dispatch }) => {
    const { flowType } = store.flow;
    const { selectedTreatment } = store.clinics;
    const { selectedDate, selectedTime, selectedAppointmentType } = store.timeSlots;
    const { email, phoneState } = store.patient;


    return (
        <div className="page--complete">
            <SectionWrapEl>
                {flowType === 'regular' || 'contact' ?
                    <div>
                        <span>{translate('complete_headline')}</span>
                        <h3><b>{selectedAppointmentType}</b></h3>
                        <p className="page--complete--date">Den {dayjs(selectedDate).locale('da').format('DD. MMMM YYYY')} <br/> kl. {selectedTime.value}</p>
                        <p className="page--complete--clinic">Hos {selectedTreatment.label} </p>
                        <DividerEl/>
                     

                     
                        {phoneState ? <div><p className="page--complete--disclaimer">{translate('complete_info_2')}</p> 
          <p className="page--complete--clinic">{phoneState.match(/.{1,2}/g).join(' ')} </p></div>
        :  <p className="page--complete--disclaimer">{translate('complete_info_3')}</p> 
      }
                        
                    </div>
                :
                    <div>
                        <p>{translate('complete_contact_1')}</p>
                        <p><b>{translate('complete_contact_2')}</b></p>
                        <DividerEl/>
                        <p>{translate('complete_info_1')}</p>
                    </div>
                }
            </SectionWrapEl>   
        </div>
    );
}

export default connectedStore(Complete);
