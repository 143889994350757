import { FC, useEffect, useState } from "react";

import dayjs from "dayjs";
import LoaderEl from "../Components/Loader/loader";
import SectionWrapEl from "../Components/Section-wrapper/Section-wrapper";
import {
  setCreateRealAppointment,
  setSubmitState,
} from "../Store/actions/flow";
import { connectedStore } from "../utils/connectedStore";
import { translate } from "../utils/translationUtils";

const Confirm: FC<any> = ({ store, dispatch }) => {
  const { submitState, questions, answers } = store.flow;
  const { fullName, cprForm, patient } = store.patient;
  const { selectedTreatment } = store.clinics;
  const {
    selectedTime,
    selectedDate,
    selectedTimeslot,
    selectedPractitioner,
    selectedAppointmentType,
  } = store.timeSlots;

  const storedCampaignId = sessionStorage.getItem("campaignId");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (submitState === "check") {
      createRealAppointment();
      fireDataLayerEvent();
      dispatch(setSubmitState("complete"));
    }
  }, [submitState]);

  const createRealAppointment = () => {
    const appointmentObj = {
      timeslot_id: selectedTimeslot,
      dentist_id: selectedPractitioner,
      patient_id: cprForm.replace(/ /g, ""),
    };
    dispatch(setCreateRealAppointment(selectedTreatment.value, appointmentObj));
  };

  const fireDataLayerEvent = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "confirm_booking",
      step_name: "contactForm",
      campaignId: storedCampaignId,
      current_step: "5",
      total_steps: "5",
      clinic: selectedTreatment?.label || "Godt Smil",
      questions: questions.map((question: any, index: number) => ({
        question: question.question,
        answer: answers[index] === 1 ? "ja" : "nej",
      })),
      chosen_date: dayjs(selectedDate).format("DDMMYYYY"),
      chosen_time: selectedTime?.value || "10:00",
      formular: {
        fornavn: patient?.FirstName || "",
        efternavn: patient?.LastName || "",
        adresse: `${patient?.StreetName || ""} ${patient?.StreetNumber || ""}`,
        postnummer: patient?.ZipCode || "",
        by: patient?.City || "",
        telefonnummer: patient?.Phone || "",
        email: patient?.Email || "test@test.",
      },
    });
  };

  return (
    <div>
      {loading && (
        <div className="page--date-select--loader-wrap">
          <LoaderEl />
        </div>
      )}

      <SectionWrapEl>
        <div>
          <span>
            {translate("dear")} {fullName} {translate("confirm_headline")}
          </span>
          <h3>
            <b>{selectedAppointmentType}</b>
          </h3>
          <p className="page--complete--date">
            Den {dayjs(selectedDate).locale("da").format("DD. MMMM YYYY")}{" "}
            <br /> kl. {selectedTime.value}
          </p>
          <p className="page--complete--clinic">
            Hos {selectedTreatment.label}
          </p>
        </div>
      </SectionWrapEl>
    </div>
  );
};

export default connectedStore(Confirm);
