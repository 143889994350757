import React, { FC } from "react";
import { SectionWrap } from './styles'

const SectionWrapEl: FC<any> = (props) => {
    return (
        <SectionWrap>{props.children}</SectionWrap>
    );
  }
  
  export default SectionWrapEl;
  