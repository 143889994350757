import { FC, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import "./App.scss";
import ButtonEl from "./Components/Button/Button";
import StepCounterEl from "./Components/Step-counter/Step-counter";
import { useFormState } from "./FormStateContext";
import Clinics from "./Pages/Clinic";
import Complete from "./Pages/Complete";
import Confirm from "./Pages/Confirm";
import ContactForm from "./Pages/ContactForm";
import DateSelect from "./Pages/DateSelect";
import Error from "./Pages/Error";
import Info from "./Pages/Info";
import InfoSimple from "./Pages/Info-simple";
import Questions from "./Pages/Questions";
import { searchClinics } from "./Store/actions/clinics";
import {
  setCampaignId,
  setClinicId,
  setQuestions,
  setStepId,
  setStepLength,
  setSubmitState,
} from "./Store/actions/flow";
import { connectedStore } from "./utils/connectedStore";
import { contactFlow, regularFlow, simpleFlow } from "./utils/flow-config";
import { translate } from "./utils/translationUtils";

const App: FC<any> = ({ store, dispatch }) => {
  const [currentStep, setCurrentStep] = useState(null) as any;
  const {
    stepId,
    flowType,
    disableButton,
    submitState,
    stepLength,
    buttonTxt,
  } = store.flow;
  // const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate() as any;
  const search = useLocation().search;
  const { clinics, selectedTreatment, loading } = store.clinics;
  const { state, dispatch: formDispatch } = useFormState();

  const [flow, setFlow] = useState(regularFlow.length);
  const visitedSteps = state.visitedSteps || [];

  const handleStepNavigation = (stepIndex: number) => {
    if (!visitedSteps.includes(stepIndex)) {
      formDispatch({
        type: "SET_VISITED_STEPS",
        payload: [...visitedSteps, stepIndex],
      });
    }
  };

  function renderSwitch(param: any) {
    switch (param) {
      case "regular":
        setFlow(regularFlow.length);
        return regularFlow.filter(
          (item) => item.path === window.location.pathname
        )[0];
      case "simple":
        setFlow(simpleFlow.length);
        return simpleFlow.filter(
          (item) => item.path === window.location.pathname
        )[0];
      case "contact":
        setFlow(contactFlow.length);
        return contactFlow.filter(
          (item) => item.path === window.location.pathname
        )[0];
      default:
        return simpleFlow.filter(
          (item) => item.path === window.location.pathname
        )[0];
    }
  }

  useEffect(() => {
    if (window.location.pathname === "/error") {
      return;
    }
    setFlow(regularFlow.length);
    const urlSearchParams = new URLSearchParams(window.location.search);
    localStorage.setItem("sessionId", uuid());
    const campaignId = urlSearchParams.get("campaignId") ?? 1;

    sessionStorage.setItem("campaignId", campaignId.toString());

    const clinicId = urlSearchParams.get("clinicId");
    dispatch(setClinicId(clinicId));
    dispatch(setCampaignId(campaignId));
    dispatch(setQuestions(campaignId));
    dispatch(searchClinics(campaignId));
    dispatch(setStepLength(0));
    if (clinicId) {
      dispatch(setStepLength(1));
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/error") {
      setCurrentStep("error");
      dispatch(setStepId("error"));
      return;
    }
    const currentStepValue = renderSwitch(flowType);

    setCurrentStep(currentStepValue);

    dispatch(setStepId(currentStepValue.stepId));
  }, [window.location.pathname]);

  useEffect(() => {
    if (submitState === "complete") {
      linkTo();
      dispatch(setSubmitState(""));
    }
  }, [submitState]);

  useEffect(() => {
    fireDataLayerEvent();
  }, [window.location.pathname]);

  const fireDataLayerEvent = () => {
    const storedCampaignId = sessionStorage.getItem("campaignId") || "14";

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "page_view",
      step_name: "booking_flow",
      current_step: stepId || "1",
      total_steps: flow || "5",
      clinic: selectedTreatment?.label || "",
      campaignId: storedCampaignId,
    });
  };

  const linkTo = () => {
    handleStepNavigation(stepId);
    const targetPath =
      flowType === "regular"
        ? regularFlow[stepId]?.path
        : flowType === "simple"
        ? simpleFlow[stepId]?.path
        : contactFlow[stepId]?.path;

    if (targetPath) {
      navigate(targetPath);
    }
  };

  const handleStepClick = (step: number) => {
    if (visitedSteps.includes(step - 1)) {
      handleStepNavigation(step - 1);
      const targetPath =
        flowType === "regular"
          ? regularFlow[step - 1]?.path
          : flowType === "simple"
          ? simpleFlow[step - 1]?.path
          : contactFlow[step - 1]?.path;

      if (targetPath) {
        setCurrentStep(renderSwitch(flowType));
        dispatch(setStepId(step - 1));
        navigate(targetPath);
      }
    } else {
      console.warn("Step not visited yet:", step);
    }
  };

  const handleNextButtonClick = () => {
    dispatch(setSubmitState("check"));
    if (window.location.pathname === "/") {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const campaignId = urlSearchParams.get("campaignId");
      dispatch(setCampaignId(campaignId));
    }
  };

  return (
    <div>
      <div className="App">
        {currentStep && (
          <>
            <div className="App--header">
              <div className="flex flex-col justify-center items-center">
                <img
                  className="App--header__logo"
                  src="GodtSmil_logo_white_TM-01.svg"
                  alt="Godt Smil Logo"
                />
                {!!!Array.isArray(selectedTreatment) && (
                  <h4 style={{ margin: "0px" }}>
                    {selectedTreatment?.label?.slice(10)}
                  </h4>
                )}
              </div>
              <hr className="my-1.5 border-white max-w-28 mx-auto" />
              <div className="text-lg">{translate("header1", true)}</div>
              <div className="App--header__bold">
                {translate("header2", true)}
              </div>
            </div>
            <div className="App--content">
              <div className="App--content__step">
                <Routes>
                  <Route path="/" element={<Clinics />} />
                  <Route path="/questions" element={<Questions />} />
                  <Route path="/info" element={<Info />} />
                  <Route path="/ContactForm" element={<ContactForm />} />
                  <Route path="/info-simple" element={<InfoSimple />} />
                  <Route path="/date-select" element={<DateSelect />} />
                  <Route path="/confirm" element={<Confirm />} />
                  <Route path="/complete" element={<Complete />} />
                  <Route path="/error" element={<Error />} />
                </Routes>
                <div className="max-sm:hidden pt-5">
                  {currentStep.btnText && (
                    <ButtonEl
                      disabled={submitState === "check" || disableButton}
                      // onClick={() => dispatch(setSubmitState("check"))}
                      onClick={handleNextButtonClick}
                      label={currentStep.btnText}
                    ></ButtonEl>
                  )}
                </div>
              </div>
            </div>
            <div className="App--footer">
              <div className="sm:hidden">
                {currentStep.btnText && (
                  <ButtonEl
                    disabled={submitState === "check" || disableButton}
                    // onClick={() => dispatch(setSubmitState("check"))}
                    onClick={handleNextButtonClick}
                    label={currentStep.btnText}
                  ></ButtonEl>
                )}
              </div>
              <StepCounterEl
                totalSteps={
                  regularFlow.filter((item) => item.stepId !== null).length - 1
                }
                currentStep={currentStep.stepId - stepLength}
                onStepClick={handleStepClick}
                visitedSteps={visitedSteps}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default connectedStore(App);
