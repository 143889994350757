import { FC, useEffect, useState } from "react";
import { Value } from "sass";
import { Api } from "../api";
import { DropdownEl } from "../Components/Dropdown/Dropdown";
import SectionWrapEl from "../Components/Section-wrapper/Section-wrapper";
import { saveClinic, searchClinics, clearClinic } from "../Store/actions/clinics";
import { clearTimeslots } from "../Store/actions/timeSlots";
import { setButtonDisabled, setSubmitState } from "../Store/actions/flow";
import { connectedStore } from "../utils/connectedStore";
import { translate } from "../utils/translationUtils";
import './pages.scss';
import LoaderEl from "../Components/Loader/loader";

const Clinics: FC<any> = ({ store, dispatch }) => {
    const [hasError, setHasError] = useState(false);

    const { clinics, selectedTreatment, loading } = store.clinics;
    const { submitState } = store.flow;
    clinics.sort((a: { label: any; }, b: { label: string; }) => a.label.localeCompare(b.label));

    useEffect(() => {
        dispatch(clearClinic(null));
        dispatch(clearTimeslots(null));
        dispatch(searchClinics(store.flow.campaignId));
      
    }, []);
    
   

  

    useEffect(() => {
        if(loading == false) {
        if(clinics.length === 1) {
            dispatch(saveClinic(clinics[0]));
            dispatch(setSubmitState('complete'));
        } 
        else if(store.flow.clinicId) {
            const found = clinics.find((clinic: any) => {
                return clinic.clinicId == store.flow.clinicId;
              });
              
              dispatch(saveClinic(found));
              dispatch(setSubmitState('complete'));
        }
    }
    }, [loading])

    useEffect(() => {
        if(submitState === 'check') {
            setHasError(false);
            if(selectedTreatment?.value) {
                dispatch(setSubmitState('complete'));
            } else {
                dispatch(setSubmitState('error'));
                setHasError(true);
            }
        } 
    }, [submitState]);

    useEffect(() => {
        dispatch(setButtonDisabled(selectedTreatment ? false : true));
    }, [selectedTreatment]);

    return (
        <div className="page--clinics" >
            <SectionWrapEl>
            { loading &&
                <div className="page--date-select--loader-wrap">
                    <LoaderEl/>
                </div>
            }
                <DropdownEl error={hasError} placeholder={translate('select_clinic', true)} dropdownList={clinics} setDropdownItem={(e: any) => dispatch(saveClinic(e))} selectedItem={selectedTreatment} />
                
            </SectionWrapEl>
            </div>
       
    );
}

export default connectedStore(Clinics);