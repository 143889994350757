import typeToReducer from "type-to-reducer";
import {
  clearTimeslots,
  clearTimeslotsInitialState,
  getTimeslots,
  setSelectedAppointmentLength,
  setSelectedAppointmentType,
  setSelectedDate,
  setSelectedPractitioner,
  setSelectedTime,
  setTimeslot,
} from "../actions/timeSlots";
import { baseActions } from "../baseActions";

export const initialState = {
  loading: true,
  timeslots: {},
  selectedTimeslot: "",
  selectedPractitioner: "",
  selectedAppointmentType: "",
  selectedDate: "",
  selectedTime: "",
  selectedAppointmentLength: "",
};

const reducer = {
  [getTimeslots as any]: {
    ...baseActions,
    FULFILLED: (state: any, { payload }: any) => {
      return {
        ...state,
        loading: false,
        timeslots: { ...state.timeslots, ...payload },
      };
    },
  },
  [setSelectedDate as any]: (state: any, { payload }: any) => ({
    ...state,
    selectedDate: payload,
  }),
  [setSelectedTime as any]: (state: any, { payload }: any) => ({
    ...state,
    selectedTime: payload,
  }),
  [setSelectedPractitioner as any]: (state: any, { payload }: any) => ({
    ...state,
    selectedPractitioner: payload,
  }),
  [setTimeslot as any]: (state: any, { payload }: any) => ({
    ...state,
    selectedTimeslot: payload,
  }),
  [setSelectedAppointmentType as any]: (state: any, { payload }: any) => ({
    ...state,
    selectedAppointmentType: payload,
  }),

  [setSelectedAppointmentLength as any]: (state: any, { payload }: any) => ({
    ...state,
    selectedAppointmentLength: payload,
  }),
  [clearTimeslots as any]: (state: any, { payload }: any) => ({
    ...state,
    timeslots: {},
  }),
  [clearTimeslotsInitialState as any]: (state: any) => ({
    ...initialState, // ✅ Reset to the initial state
  }),
};

export default typeToReducer(reducer, initialState);
