import { FC, useEffect, useState } from "react";

import SectionWrapEl from "../Components/Section-wrapper/Section-wrapper";
import {setCreateRealAppointment, setSubmitState } from "../Store/actions/flow";
import { connectedStore } from "../utils/connectedStore";
import { translate } from "../utils/translationUtils";
import dayjs from "dayjs";
import LoaderEl from "../Components/Loader/loader";



const Confirm: FC<any> = ({ store, dispatch }) => {
  const { submitState } = store.flow;
  const { fullName,  cprForm } = store.patient;
  const { selectedTreatment } = store.clinics;
  const { selectedTime, selectedDate, selectedTimeslot, selectedPractitioner, selectedAppointmentType } = store.timeSlots;

  const [loading, setLoading] = useState(false);



  useEffect(() => {
    if(submitState === 'check') {
          createRealAppointment();
          dispatch(setSubmitState('complete'));
    }
       
  }, [submitState]);

  const createRealAppointment = () => {
    const appointmentObj = {
      timeslot_id: selectedTimeslot, 
      dentist_id: selectedPractitioner,
      patient_id: cprForm.replace(/ /g, '')
    }
    dispatch(setCreateRealAppointment(selectedTreatment.value, appointmentObj));
  }



  return (
    <div>
      {loading &&
        <div className="page--date-select--loader-wrap">
          <LoaderEl/>
        </div>
      }
   
            <SectionWrapEl>
               <div>
                        <span>{translate('dear')} {fullName} {translate('confirm_headline')}</span>
                        <h3><b>{selectedAppointmentType}</b></h3>
                        <p className="page--complete--date">Den {dayjs(selectedDate).locale('da').format('DD. MMMM YYYY')} <br/> kl. {selectedTime.value}</p>
                        <p className="page--complete--clinic">Hos {selectedTreatment.label}</p>
                      
                    </div>
            </SectionWrapEl>
     
    </div>
  );
}

export default connectedStore(Confirm);
