import throttle from "lodash.throttle";
import createRoot from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Colors } from "./colors";
import { FormStateProvider } from "./FormStateContext";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import configureStore, { history, loadState, saveState } from "./Store/store";

const initialState = (window as any).initialReduxState;
const persistedState = loadState();

const store = configureStore(
  history,
  persistedState ? persistedState : initialState
);

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);

document.documentElement.style.setProperty("--primaryColor", Colors.primary);

createRoot.render(
  <Provider store={store}>
    <FormStateProvider>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      ></meta>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </FormStateProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
