import {
    Spinner
} from './styles'

const LoaderEl = () => {
    return (
        <Spinner><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></Spinner>
    );
  }
  
export default LoaderEl;
  