import dayjs from "dayjs";
import { FC, useEffect } from "react";
import DividerEl from "../Components/Divider/Divider";
import SectionWrapEl from "../Components/Section-wrapper/Section-wrapper";
import { connectedStore } from "../utils/connectedStore";
import { translate } from "../utils/translationUtils";

const Complete: FC<any> = ({ store, dispatch }) => {
  const { flowType, questions, answers } = store.flow;
  const { selectedTreatment } = store.clinics;
  const { selectedDate, selectedTime, selectedAppointmentType } =
    store.timeSlots;
  const { email, phoneState, patient } = store.patient;

  const storedCampaignId = sessionStorage.getItem("campaignId");

  useEffect(() => {
    fireDataLayerEvent();
  }, []);

  const fireDataLayerEvent = () => {
    const storedFormularData = sessionStorage.getItem("formularData");
    const formular = storedFormularData ? JSON.parse(storedFormularData) : {};

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "booking_complete",
      step_name: "complete",
      campaignId: storedCampaignId,
      current_step: "complete",
      total_steps: "5",
      clinic: selectedTreatment?.label || "Godt Smil Odense",
      questions: questions.map((question: any, index: number) => ({
        question: question.question,
        answer: answers[index] === 1 ? "ja" : "nej",
      })),
      chosen_date: dayjs(selectedDate).format("DDMMYYYY"),
      chosen_time: selectedTime?.value || "10:00",
      formular: formular,
    });
  };

  return (
    <div className="page--complete">
      <SectionWrapEl>
        {flowType === "regular" || "contact" ? (
          <div>
            <span>{translate("complete_headline")}</span>
            <h3>
              <b>{selectedAppointmentType}</b>
            </h3>
            <p className="page--complete--date">
              Den {dayjs(selectedDate).locale("da").format("DD. MMMM YYYY")}{" "}
              <br /> kl. {selectedTime.value}
            </p>
            <p className="page--complete--clinic">
              Hos {selectedTreatment.label}{" "}
            </p>
            <DividerEl />

            {phoneState ? (
              <div>
                <p className="page--complete--disclaimer">
                  {translate("complete_info_2")}
                </p>
                <p className="page--complete--clinic">
                  {phoneState.match(/.{1,2}/g).join(" ")}{" "}
                </p>
              </div>
            ) : (
              <p className="page--complete--disclaimer">
                {translate("complete_info_3")}
              </p>
            )}
          </div>
        ) : (
          <div>
            <p>{translate("complete_contact_1")}</p>
            <p>
              <b>{translate("complete_contact_2")}</b>
            </p>
            <DividerEl />
            <p>{translate("complete_info_1")}</p>
          </div>
        )}
      </SectionWrapEl>
    </div>
  );
};

export default connectedStore(Complete);
