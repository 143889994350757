import styled from 'styled-components'
import { Colors } from '../../colors'
import { Radio, withStyles } from '@material-ui/core'

export const RadioBtn = withStyles({
    root: {
        "&$checked": {
            color: Colors.primary,
        }
    },
    checked: {}
})((props) => <Radio color="default" {...props} />);