import React, { FC } from "react";
import { translate } from "../../utils/translationUtils";
import {
    PrimaryBtn
} from './styles'

const ButtonEl: FC<any> = ({label, onClick, disabled}) => {
    return (
        <PrimaryBtn 
            disabled={disabled}
            onClick={onClick}
        >
            {translate(label, true)}
        </PrimaryBtn>
    );
  }
  
  export default ButtonEl;
  