export const Colors = {
  primary: "#ff9d00",
  white: "#FFF",
  black: "#000",
  text: "#000426",
  text_light: "#BEBEBE",
  border: "#BEBEBE",
  error: "#FC0000",
  background: "rgb(249,249,249)",
  text_blue: "#1D4A63",
  orange_light: "#FFF2E0",
};
