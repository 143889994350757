const baseUrl = 'https://gsbooking.dentalmedia.io/core/api/'
const  sessionId = window.localStorage.getItem('sessionId');
const sessionIdString: string = sessionId !== null ? sessionId : '';

export const Api = {
    fetchQuestions: (campaignId: number) => {
        return fetchFromApi(`campaign/${campaignId}/questions`, sessionIdString);
    },
    fetchClinics: (campaignId: number) => {
        return fetchFromApi(`campaign/${campaignId}/clinics`, sessionIdString);
    },
    fetchTimeSlots: (treatment_id: number, pageId: number) => {
        return fetchFromApi(`treatment/timeslots/${treatment_id}/${pageId}`, sessionIdString);
    },
    createDummyAppointment: (treatment_id: number, appointmentObj: object) => {
        return PostToApi(`clinic/create-dummy-appointment/${treatment_id}`, appointmentObj, 'POST', sessionIdString)
    },
    createRealAppointment: (treatment_id: number, appointmentObj: object) => {
        return PostToApi(`clinic/create-real-appointment/${treatment_id}`, appointmentObj, 'POST', sessionIdString)
    },
    fetchPatient: (clinicId: number, cpr: any, campaignId: any) => {
        return PostToApi(`clinic/${clinicId}/patient-lookup`, {ID : cpr, campaign_id: campaignId} , 'POST', sessionIdString);
    },
    createPatient: (clinicId: number, patientObj: object) => {
        return PostToApi(`clinic/${clinicId}/create-patient`, patientObj, 'POST', sessionIdString);
    },
    sendPatientContactInfo: (clinicId: number, contactObj: object) => {
        return PostToApi(`clinic/${clinicId}/send-contact-form`, contactObj, 'POST', sessionIdString)
    }
};

const fetchFromApi = async (path: string, sessionId: string) => {
    try {
        const options = {
          
            headers: {
                'Session-Id': sessionId
            }
        }
        const returnObj = await fetch(baseUrl + path, options);
        // Check if status code is not successful
        if(Math.floor(returnObj.status / 100) !== 2) {
            window.location.href = '/error';
            throw Error('Status code not 2xx');
        }        
        return  returnObj.json();
    }
    catch (e) {
        throw console.error(e);
        
    }
};

const PostToApi = async (path: string, data: any, method: string, sessionId: string) => {
    try {
        const options = {
            method,
            body: (typeof data !== 'undefined') ? JSON.stringify(data) : void 0,
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Session-Id': sessionId
            }
        }
        const returnObj = await fetch(baseUrl + path, options);
        // Check if status code is not successful
        if(Math.floor(returnObj.status / 100) !== 2) {
            window.location.href = '/error';
            throw Error('Status code not 2xx');
        }
        return  returnObj.json();
    }
    catch (e) {
        window.location.href = '/error';
        throw console.error(e);
    }
};
