import typeToReducer from "type-to-reducer";
import { clearClinic, saveClinic, searchClinics } from "../actions/clinics";
import { baseActions } from "../baseActions";

export const initialState = {
  loading: true,
  clinics: [],
  selectedTreatment: {},
  treatments: [],
};

const reducer = {
  [searchClinics as any]: {
    ...baseActions,
    FULFILLED: (state: any, { payload }: any) => {
      return {
        ...state,
        clinics: payload.treatments.map((treatment: any) => ({
          value: treatment.id,
          clinicId: treatment.clinic.id,
          label: treatment.clinic.name,
        })),
        treatments: payload.treatmements,
        loading: false,
      };
    },
  },
  [saveClinic as any]: (state: any, { payload }: any) => ({
    ...state,
    selectedTreatment: payload,
  }),
  [clearClinic as any]: (state: any, { payload }: any) => ({
    ...state,
    selectedTreatment: [],
  }),
};

export default typeToReducer(reducer, initialState);
