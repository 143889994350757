import React, { FC, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import DividerEl from "../Components/Divider/Divider";
import RadioEl from "../Components/Radio/Radio";
import SectionWrapEl from "../Components/Section-wrapper/Section-wrapper";
import { connectedStore } from "../utils/connectedStore";
import { setAnswers, setButtonDisabled, setFlowType, setSubmitState, setStepLength } from "../Store/actions/flow";
import './pages.scss';
import LoaderEl from "../Components/Loader/loader";


const Questions: FC<any> = ({ store, dispatch }) => {
    const { submitState, questions, answers } = store.flow;
    const { clinics, selectedTreatment, loading, treatments } = store.clinics;

    const [hasError, setHasError] = useState(false);

    useEffect(() => {
       
        if(clinics.length === 1) {
           
            dispatch(setStepLength(1));
        }
    }, [loading]);

    useEffect(() => {
        if(submitState === 'check') {
            setHasError(false);
            if(answers.filter((answer: string) => answer === null).length > 0 || answers.length < questions.length) {
                setHasError(true);
                dispatch(setSubmitState('error'));
                return;
            }
            const correctAnswers: Array<string> = questions.map((question: any) => question.expected_answer);
            const isCorrectSelected: boolean = JSON.stringify(correctAnswers) === JSON.stringify(answers);
            dispatch(setFlowType(isCorrectSelected ? 'regular' : 'simple'));
            dispatch(setSubmitState('complete'));
        } 
    }, [submitState]);

    useEffect(() => {
        let disableButton = false;
        if(answers.filter((answer: string) => answer === null).length > 0 || answers.length < questions.length) {
            disableButton = true;
        }
        dispatch(setButtonDisabled(disableButton));
    }, [answers]); 

    const setQuestionAnswer = (questionIndex: number, value: any) => {
        let updatedValues: Array<any> = answers;
        updatedValues[questionIndex] = value;
        dispatch(setAnswers([...updatedValues]));
    }

    return (
        <div className="page--questions">
            <SectionWrapEl>
            { loading &&
                <div className="page--date-select--loader-wrap">
                    <LoaderEl/>
                </div>
            }
                <h2>For at sikre korrekt booking, beder vi dig svare på {questions.length} spørgsmål:</h2>
                {questions.map((question: any, index: number) => (
                    <React.Fragment key={index}>
                        {index !== 0 && <DividerEl/>}
                        <div>
                            <p className="page--questions--question">{question.question}</p>
                            <Box display='flex'>
                                <div onClick={() => setQuestionAnswer(index, 1)}>
                                    <RadioEl error={hasError && (answers[index] === null || !answers[index])} value={1} checked={answers[index] === 1}/>
                                    Ja
                                </div>
                                <div onClick={() => setQuestionAnswer(index, 0)}>
                                    <RadioEl error={hasError && (answers[index] === null || !answers[index])} value={0}  checked={answers[index] === 0}/> 
                                   Nej
                                </div>
                            </Box>
                        </div>
                    </React.Fragment>
                ))}
            </SectionWrapEl>
        </div>
    );
}

export default connectedStore(Questions);
