import React from 'react';
import createRoot from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import configureStore, { history, loadState, saveState } from './Store/store';
import throttle from 'lodash.throttle';
import { Colors } from './colors';

const initialState = (window as any).initialReduxState;
const persistedState = loadState();

const store = configureStore(history,  persistedState ? persistedState : initialState);

store.subscribe(throttle(() => {
  saveState(store.getState());
}, 1000));

document.documentElement.style.setProperty(
  '--primaryColor',
  Colors.primary
);

createRoot.render(
  <Provider store={store}>
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
