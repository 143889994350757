import React, { FC } from "react";
import { 
    StepCounter,
    CountItem 
} from './styles'

const StepCounterEl: FC<any> = ({
    totalSteps,
    currentStep
}) => {
    return (
        <div>

            <StepCounter>
                {[...Array(totalSteps).keys() as any].map((item) => (
                    <CountItem active={item + 1 === currentStep} key={item}>
                        {item + 1}
                    </CountItem>
                ))}
            </StepCounter>
        </div>
    );
  }
  
  export default StepCounterEl;
  