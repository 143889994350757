import React, { FC, useState } from 'react'
import { translate } from '../../utils/translationUtils'
import {
    DropdownPickerField,
    DropdownPickerFieldIos,
    DropdownPickerList,
    DropdownPickerItem,
    DropdownPickerPlaceholder,
    DropdownWrap
} from './styles'
import Box from '@material-ui/core/Box'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

export const DropdownEl: FC<any> = ({
    dropdownList = [],
    selectedItem,
    setDropdownItem,
    placeholder,
    error = false
}) => {
    let value = "";
    const [dropdownOpened, setDropdownOpened] = useState(false);
    const _iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);
    const toggleDropdown = () => {
        setDropdownOpened(!dropdownOpened)
    };
    if(selectedItem.value) {
        value = selectedItem.value;
    }
    
    return (
        <DropdownWrap>                
     
                <DropdownPickerFieldIos
                    value={value} 
                    onChange={(e: any) => {setDropdownItem(dropdownList.find((item: any) => item.value.toString() === e.target.value.toString()));}}>
                    
                    <option disabled={true} value="" >{translate(placeholder, true)}</option>
                    {dropdownList.map((item: any, index: number) => (
                        <option key={index} value={item.value}>{item.label}</option>
                    ))}
                </DropdownPickerFieldIos>
       
        </DropdownWrap>
    )
}
