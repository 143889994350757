import styled from 'styled-components'
import { Colors } from '../../colors'
import { Checkbox, withStyles } from '@material-ui/core'

const InputError = styled.div`
    font-size: 14px;
    color: red;
`

const InputMessage = styled.div`
    font-size: 11px;
    color: ${Colors.border};
    font-weight: 500;
    line-height: 1.2;
    margin-left: 7px;
    margin-top: 10px;
`

const InputWrapper = styled.div`
    position: relative;
`

const InputLabel = styled.span`
    color: ${Colors.text};
    display: flex;
    margin-top: .75rem;
    font-size: 20px;
    font-weight: 600;
`

const Input = styled.input`
    margin-top: .5rem;
    padding: 10px;
    width: -webkit-fill-available;
    border: ${({hasError}) => (hasError ? '1px solid red' : '1px solid ' + Colors.border)};
    border-radius: 4px;
    outline: none;
    background-color: ${Colors.white};
    font-size: 20px;
`

const InputRequiredTag = styled.div`
    color: ${Colors.error};
    font-size: 1rem;
`

const TextArea = styled.textarea`
    font-family: Arial, Helvetica, sans-serif;
    min-height: 120px;
`

const StyledMUICheckbox = withStyles({
    root: {
        color: Colors.primary,
        fontSize: 16,
        '&$checked': {
            color: Colors.primary,
        },
    },
    checked: {},
})(Checkbox)

const RequiredIco = styled.div`
    color: ${Colors.red};
    position: absolute;
    font-size: 14px;
    right: ${({ right }) => right || -7}px;
    top: ${({ top }) => top || 20}px;
`

export {
    Input,
    TextArea,
    InputError,
    InputLabel,
    RequiredIco,
    InputWrapper,
    InputMessage,
    StyledMUICheckbox,
    InputRequiredTag
}
