import { FC, useEffect } from "react";
import { PrimaryBtn } from "../Components/Button/styles";
import SectionWrapEl from "../Components/Section-wrapper/Section-wrapper";
import { setCampaignId } from "../Store/actions/flow";
import { connectedStore } from "../utils/connectedStore";
import { translate } from "../utils/translationUtils";

const Error: FC<any> = ({ store, dispatch }) => {
  const { campaignId, questions, answers } = store.flow;
  const { selectedTreatment } = store.clinics;
  const storedCampaignId = sessionStorage.getItem("campaignId");

  const resetForm = () => {
    sessionStorage.removeItem("state");
    dispatch(setCampaignId(campaignId));
    window.location.href = "/";
  };

  useEffect(() => {
    fireDataLayerEvent();
  }, []);

  const fireDataLayerEvent = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "booking_error",
      campaignId: storedCampaignId,
      current_step: "3",
      total_steps: "5",
      clinic: selectedTreatment?.label || "",
      questions: questions.map((question: any, index: number) => ({
        question: question.question,
        answer: answers[index] === 1 ? "ja" : "nej",
      })),
      error: "Der er desværre sket en fejl!",
    });
  };

  return (
    <div className="page--error">
      <SectionWrapEl>
        <h2>{translate("error_headline")}</h2>
        <PrimaryBtn onClick={resetForm}>{translate("error_button")}</PrimaryBtn>
      </SectionWrapEl>
    </div>
  );
};

export default connectedStore(Error);
