import { createAction } from "@reduxjs/toolkit";
import { Api } from "../../api";

export const getTimeslots = createAction(
  "GET_TIMESLOTS",
  (clinicId, pageId) => ({
    payload: Api.fetchTimeSlots(clinicId, pageId),
  })
);

export const setSelectedDate = createAction("SET_SELECTED_DATE", (data) => ({
  payload: data,
}));

export const setSelectedTime = createAction("SET_SELECTED_TIME", (data) => ({
  payload: data,
}));

export const setTimeslot = createAction("SET_TIMESLOT", (data) => ({
  payload: data,
}));

export const setSelectedPractitioner = createAction(
  "SET_SELECTED_PRACTITIONER",
  (data) => ({
    payload: data,
  })
);

export const setSelectedAppointmentType = createAction(
  "SET_SELECTED_APPOINTMENT_TYPE",
  (data) => ({
    payload: data,
  })
);

export const setSelectedAppointmentLength = createAction(
  "SET_SELECTED_APPOINTMENT_LENGTH",
  (data) => ({
    payload: data,
  })
);

export const clearTimeslots = createAction("CLEAR_TIMESLOTS", (data) => ({
  payload: data,
}));

export const clearTimeslotsInitialState = createAction(
  "CLEAR_TIMESLOTS_INITIAL_STATE"
);
