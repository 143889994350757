import { createAction } from "@reduxjs/toolkit"
import { Api } from "../../api";
import clinic from "../reducers/clinic";

export const setStepId = createAction('SET_STEP_ID', stepId => ({
    payload: stepId
}));

export const setFlowType = createAction('SET_FLOW_TYPE', flow => ({
    payload: flow,
}));

export const setStepLength = createAction('SET_STEP_LENGTH', stepLength => ({
    payload: stepLength,
}));

export const setCampaignId = createAction('SET_CAMPAIGN_ID', campaignId => ({
    payload: campaignId,
}));

export const setSubmitState = createAction('SET_CHECK_SUBMIT', isChecking => ({
    payload: isChecking,
}));

export const setButtonDisabled = createAction('SET_BUTTON_DISABLED', isDisabled => ({
    payload: isDisabled,
}));

export const setSessionId = createAction('SET_SESSION_ID', sessionId => ({
    payload: sessionId,
}));

export const setClinicId = createAction('SET_CLINIC_ID', clinicId => ({
    payload: clinicId,
}));

export const setButtonTxt = createAction('SET_BUTTON_TXT', buttonTxt => ({
    payload: buttonTxt,
}));

export const setQuestions = createAction('SET_QUESTIONS', (campaignId) => {
    return {
        payload: Api.fetchQuestions(campaignId)
    }
})

export const setAnswers = createAction('SET_ANSWERS', answerList => ({
    payload: answerList,
}));

export const setCreateDummyAppointment = createAction('SET_CREATE_DUMMY_APPOINTMENT', (treatment_id, appointment) => ({
    payload: Api.createDummyAppointment(treatment_id, appointment),
}));

export const setCreateRealAppointment = createAction('SET_CREATE_REAL_APPOINTMENT', (treatment_id, appointment) => ({
    payload: Api.createRealAppointment(treatment_id, appointment),
}));