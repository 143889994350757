import React, { FC } from "react";
import { RadioBtn } from './styles'

const RadioEl: FC<any> = ({value, checked, onChange, error}) => {
    return (
        <RadioBtn style={error ? {color: 'red'} : {}} value={value} checked={checked} onChange={onChange}></RadioBtn>
    );
  }
  
  export default RadioEl;
  