import React, { createContext, ReactNode, useContext, useReducer } from "react";

interface FormState {
  visitedSteps: number[];
  dateSelect: {
    selectedDate: string | null;
    selectedTime: { value: string; label: string } | null;
  };
  infoPage: {
    cpr: string;
    firstName: string;
    lastName: string;
    streetName: string;
    streetNumber: string;
    postNumber: string;
    city: string;
    mobile: string;
    email: string;
    policy1: boolean;
    policy2: boolean;
  };
}

type Action =
  | { type: "SET_SELECTED_DATE"; payload: string }
  | { type: "SET_SELECTED_TIME"; payload: { value: string; label: string } }
  | { type: "SET_CPR"; payload: string }
  | { type: "SET_FIRST_NAME"; payload: string }
  | { type: "SET_LAST_NAME"; payload: string }
  | { type: "SET_STREET_NAME"; payload: string }
  | { type: "SET_STREET_NUMBER"; payload: string }
  | { type: "SET_POST_NUMBER"; payload: string }
  | { type: "SET_CITY"; payload: string }
  | { type: "SET_MOBILE"; payload: string }
  | { type: "SET_EMAIL"; payload: string }
  | { type: "SET_POLICY_1"; payload: boolean }
  | { type: "SET_POLICY_2"; payload: boolean }
  | { type: "SET_VISITED_STEPS"; payload: number[] }
  | { type: "RESET_FORM_STATE" };

const initialState: FormState = {
  dateSelect: {
    selectedDate: null,
    selectedTime: null,
  },
  infoPage: {
    cpr: "",
    firstName: "",
    lastName: "",
    streetName: "",
    streetNumber: "",
    postNumber: "",
    city: "",
    mobile: "",
    email: "",
    policy1: false,
    policy2: false,
  },
  visitedSteps: [0],
};

const FormStateContext = createContext<{
  state: FormState;
  dispatch: React.Dispatch<Action>;
} | null>(null);

const formStateReducer = (state: FormState, action: Action): FormState => {
  switch (action.type) {
    case "SET_SELECTED_DATE":
      return {
        ...state,
        dateSelect: { ...state.dateSelect, selectedDate: action.payload },
      };
    case "SET_SELECTED_TIME":
      return {
        ...state,
        dateSelect: { ...state.dateSelect, selectedTime: action.payload },
      };
    case "SET_CPR":
      return { ...state, infoPage: { ...state.infoPage, cpr: action.payload } };
    case "SET_FIRST_NAME":
      return {
        ...state,
        infoPage: { ...state.infoPage, firstName: action.payload },
      };
    case "SET_LAST_NAME":
      return {
        ...state,
        infoPage: { ...state.infoPage, lastName: action.payload },
      };
    case "SET_STREET_NAME":
      return {
        ...state,
        infoPage: { ...state.infoPage, streetName: action.payload },
      };
    case "SET_STREET_NUMBER":
      return {
        ...state,
        infoPage: { ...state.infoPage, streetNumber: action.payload },
      };
    case "SET_POST_NUMBER":
      return {
        ...state,
        infoPage: { ...state.infoPage, postNumber: action.payload },
      };
    case "SET_CITY":
      return {
        ...state,
        infoPage: { ...state.infoPage, city: action.payload },
      };
    case "SET_MOBILE":
      return {
        ...state,
        infoPage: { ...state.infoPage, mobile: action.payload },
      };
    case "SET_EMAIL":
      return {
        ...state,
        infoPage: { ...state.infoPage, email: action.payload },
      };
    case "SET_POLICY_1":
      return {
        ...state,
        infoPage: { ...state.infoPage, policy1: action.payload },
      };
    case "SET_POLICY_2":
      return {
        ...state,
        infoPage: { ...state.infoPage, policy2: action.payload },
      };
    case "SET_VISITED_STEPS":
      return {
        ...state,
        visitedSteps: action.payload,
      };
    case "RESET_FORM_STATE":
      return initialState;
    default:
      return state;
  }
};

export const FormStateProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(formStateReducer, initialState);

  return (
    <FormStateContext.Provider value={{ state, dispatch }}>
      {children}
    </FormStateContext.Provider>
  );
};

export const useFormState = () => {
  const context = useContext(FormStateContext);
  if (!context) {
    throw new Error("useFormState must be used within a FormStateProvider");
  }
  return context;
};
