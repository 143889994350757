export const baseActions = {
    PENDING: (state: any) => ({
        ...state,
        loading: true,
    }),
    REJECTED: (state: any) => ({
        ...state,
        loading: false,
    }),
}
