import { createTheme } from '@material-ui/core'
import { Colors } from '../../colors'

export const datePickerTheme = (selectedDate, isMobile) =>
    createTheme({
        overrides: {
            MuiPickersStaticWrapper: {
                staticWrapperRoot: {
                    backgroundColor: 'transparent',
                    minWidth: '100%',
                    overflow: 'visible'
                }
            },
            MuiPickersBasePicker: {
                container: {
                    alignItems: 'center',
                },
                pickerView: {
                    minHeight: 380,
                    maxWidth: isMobile ? 325 : '90%',
                    minWidth: isMobile ? 310 : '90%',
                },
            },
            MuiButtonBase: {
                root: { backgroundColor: 'transparent' },
            },
            MuiTypography: {
                body1: {
                    fontSize: 16,
                    color: Colors.text,
                },
                body2: { 
                    fontSize: 13,
                    fontWeight: 600,
                },
                caption: {
                    fontSize: 12,
                    textTransform: 'capitalize',
                },
            },
            MuiPickersCalendarHeader: {
                daysHeader: {
                    justifyContent: isMobile ? 'center' : 'space-between',
                },
                dayLabel: {
                    color: Colors.text,
                    width: isMobile ? 40 : 65,
                },
                iconButton: {
                    backgroundColor: Colors.white,
                    borderRadius: '100%',
                    boxShadow: '0px 2px 3px 0px rgba(0,0,0,0.25)',
                    '&:hover': {
                        backgroundColor: Colors.white
                    },
                },
                switchHeader: {
                    textTransform: 'capitalize',          
                    marginTop: -65,                  
                },
            },
            MuiPickersCalendar: {
                week: {
                    marginBottom: 7,
                    justifyContent: isMobile ? 'center' : 'space-between',
                },
            },
            MuiPickersDay: {
                day: {
                    color: Colors.text,
                    width: 40,
                    height: 40,
                },
                daySelected: {
                    color: Colors.white,
                    backgroundColor: selectedDate ? Colors.primary : 'transparent',
                    borderRadius: '100%',
                    '&:hover': {
                        backgroundColor: Colors.primary,
                    },
                },
                dayDisabled: {
                    color: Colors.border,
                },
            },
        },
    })
