import { baseActions } from '../baseActions'
import typeToReducer from 'type-to-reducer'
import { setSubmitState, setCampaignId, setFlowType, setStepId, setAnswers, setQuestions, setCreateRealAppointment, setButtonDisabled, setClinicId, setStepLength, setButtonTxt } from '../actions/flow';

const initialState = {
    loading: false,
    disableButton: true,
    flowType: 'short',
    stepLength: 0,
    stepId: 0,
    campaignId: 0,
    clinicId: null,
    submitState: '',
    questions: [],
    answers: [],
    buttonTxt:''
};

const reducer = {
    [setStepId as any]: (state: any, { payload }: any) => ({
        ...state,
        stepId: payload,
    }),
    [setFlowType as any]: (state: any, { payload }: any) => ({
        ...state,
        flowType: payload,
    }),
    [setStepLength as any]: (state: any, { payload }: any) => ({
        ...state,
        stepLength: payload,
    }),
    [setCampaignId as any]: (state: any, { payload }: any) => ({
        ...state,
        campaignId: payload,
    }),
    [setClinicId as any]: (state: any, { payload }: any) => ({
        ...state,
        clinicId: payload,
    }),
    [setSubmitState as any]: (state: any, { payload }: any) => ({
        ...state,
        submitState: payload
    }),
    [setButtonDisabled as any]: (state: any, { payload }: any) => ({
        ...state,
        disableButton: payload
    }),
    [setButtonTxt as any]: (state: any, { payload }: any) => ({
        ...state,
        buttonTxt: payload
    }),
    [setQuestions as any]: {
        ...baseActions,
        FULFILLED: (state: any, { payload }: any) => {
            return {
                ...state,
                loading: false,
                questions: payload.questions
            }
        },
    },
    [setAnswers as any]: (state: any, { payload }: any) => ({
        ...state,
        answers: payload
    }),
    [setCreateRealAppointment as any]: (state: any, { payload }: any) => ({
        ...state,
        answers: payload
    })    
};

export default typeToReducer(reducer as any, initialState);