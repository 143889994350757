import { Colors } from '../../colors'
import { Button, withStyles } from '@material-ui/core'

const btnRoot = {
    minWidth: 190,
    boxShadow: 'none',
    fontSize: 14,
    padding: '10px 16px',
    backgroundColor: '#ff9d00',
    textTransform: 'initial',
    borderRadius: 20,
    color: '#fff',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#ff9d00',
    },
    '&:disabled': {
        backgroundColor: Colors.border + '!important',
    },
}

const PrimaryBtn = withStyles({
    root: btnRoot,
})(Button)

const PrimaryBtnError = withStyles({
    root: {
        ...btnRoot,
        backgroundColor: 'rgba(255, 194, 153, 1)',
        color: Colors.black,
        '&:hover': {
            backgroundColor: 'rgba(252,0,0,0.6)',
        },
    },
})(Button)


export {
    PrimaryBtn,
    PrimaryBtnError
}
