import { FC, useEffect, useState } from "react";
import InputEl from "../Components/Input/Input";
import SectionWrapEl from "../Components/Section-wrapper/Section-wrapper";
import { searchPatient, setPatient, setEmailState, setCPRState } from "../Store/actions/patient";
import { setButtonDisabled, setCreateRealAppointment, setSubmitState, setFlowType, setButtonTxt } from "../Store/actions/flow";
import { connectedStore } from "../utils/connectedStore";
import { translate } from "../utils/translationUtils";
import dayjs from "dayjs";
import LoaderEl from "../Components/Loader/loader";
import { Checkbox } from "@material-ui/core";
import cprLogo from '../assets/cpr.svg';
import cprLogoSvg from '../assets/cprLogo.svg';



const Info: FC<any> = ({ store, dispatch }) => {
  const { submitState, campaignId } = store.flow;
  const { fullName, patientFound } = store.patient;
  const { selectedTreatment } = store.clinics;
  const { selectedTime, selectedDate, selectedTimeslot, selectedPractitioner, selectedAppointmentType } = store.timeSlots;

  const [checkingCPR, setCheckingCPR] = useState(true); 
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [streetName, setStreetName] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [postNumber, setPostNumber] = useState('');
  const [city, setCity] = useState('');
  const [cpr, setCpr] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [policy1, setPolicy1] = useState(false);
  const [policy2, setPolicy2] = useState(false);

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

  const cprRegex = /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])\d{2}?\s[-]?\s\d{4}$/gm

  
  useEffect(() => {
    let disableButton = false;
    if(checkingCPR && (cpr === '' || !cpr.slice(0, 13).match(cprRegex))) {
        disableButton = true;
    }
    if(!checkingCPR && (!policy1 || !policy2 || firstName === '' || lastName === '' || streetName === '' || streetNumber === '' || postNumber === '' || postNumber.length !== 4 || city === '' || cpr === '' || mobile === '' || mobile.length !== 8 || email === '')) {
      disableButton = true;
    }
    if(patientFound) {
      disableButton = false;
    }
    dispatch(setButtonDisabled(disableButton));
  }, [checkingCPR, cpr, policy1, policy2, firstName, lastName, streetName, streetNumber, postNumber, city, cpr, mobile, email]); 

  useEffect(() => {

    if(submitState === 'check') {
        if(checkingCPR) {
          if(cpr === '' || !cpr.slice(0, 13).match(cprRegex)) {
            setHasError(true);
            dispatch(setSubmitState('error'));
            return;
          }
          setHasError(false);
          setLoading(true);
          dispatch(searchPatient(selectedTreatment.clinicId, cpr, campaignId)).then(() => {
            setLoading(false);
            setCheckingCPR(false);
          dispatch(setSubmitState('confirm'));
          return;
          });
        }
      } else if((submitState === 'confirm')) {
        if(patientFound) {
          dispatch(setCPRState(cpr));
          dispatch(setSubmitState('complete'));
        } else {
           dispatch(setFlowType('contact'))
           dispatch(setCPRState(cpr));
           
         dispatch(setSubmitState('complete'));   
    } 
  }
  }, [submitState]);

  const setTransformedCpr = (e : any) => {
    
    if(e.length < 13) {
      setCpr(e.replace(/[^\dA-Z]/g, '').replace(/(.{6})/g, '$1 - ').trim());
    }
    else {
      setCpr(e.slice(0, 13));
    }

  }

  return (
    <div>
      {loading &&
        <div className="page--date-select--loader-wrap">
          <LoaderEl/>
        </div>
      }

        <SectionWrapEl>
          <div className="page--info--cpr-wrap">
           
            <div className="page--info--cpr-wrap--input">
              <InputEl type={'tel'}  error={hasError && (cpr === '' || !cpr.match(cprRegex))} label={'cprNumber'} placeholder={'XXXXXX - XXXX'} value={cpr} setValue={setTransformedCpr} required/>
            </div>
          </div>
          <div className="page--info-simple">
          <div className="page--info-simple--cpr-wrapper">
              <img width='45px' height='45px' src={cprLogoSvg}/> 
          <p className="page--info-simple--cpr-2"> Du kan trygt indtaste </p>
          <p className="page--info-simple--cpr-2">dit CPR-nummer </p>
        
          </div>
            <p> Vi krypterer og behandler det med maksimal datasikkerhed.</p> 

          </div>
        </SectionWrapEl>
    
    </div>
  );
}

export default connectedStore(Info);
