import { createAction } from "@reduxjs/toolkit";
import { Api } from "../../api";

export const searchPatient = createAction(
  "FETCH_PATIENT",
  (clinicId, cpr, campaignId) => {
    return {
      payload: Api.fetchPatient(clinicId, cpr.replace(/ /g, ""), campaignId),
    };
  }
);

export const setPatient = createAction(
  "SET_PATIENT",
  (clinicId, patientObj) => {
    return {
      payload: Api.createPatient(clinicId, patientObj),
    };
  }
);

export const setPatientContact = createAction(
  "SET_PATIENT_CONTACT",
  (clinicId, contactObj) => {
    return {
      payload: Api.sendPatientContactInfo(clinicId, contactObj),
    };
  }
);

export const setEmailState = createAction("SET_EMAIL_STATE", (data) => ({
  payload: data,
}));

export const setCPRState = createAction("SET_CPR_STATE", (data) => ({
  payload: data,
}));

export const setPhoneState = createAction("SET_PHOSE_STATE", (data) => ({
  payload: data,
}));

export const clearPatientInitialState = createAction(
  "CLEAR_PATIENT_INITIAL_STATE"
);
