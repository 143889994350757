import { FC } from "react";
import { CountItem, StepCounter } from "./styles";

const StepCounterEl: FC<any> = ({
  totalSteps,
  currentStep,
  onStepClick,
  visitedSteps,
}) => {
  const isComplete = window.location.pathname === "/complete";

  return (
    <div>
      <StepCounter>
        {[...(Array(totalSteps).keys() as any)].map((item) => {
          const stepIndex = item + 1;
          const isVisited = visitedSteps.includes(stepIndex - 1);
          const isActive = stepIndex === currentStep;

          return (
            <CountItem
              active={!isComplete && isActive}
              visited={!isComplete && isVisited}
              key={item}
              onClick={() => !isComplete && isVisited && onStepClick(stepIndex)}
            >
              {stepIndex}
            </CountItem>
          );
        })}
      </StepCounter>
    </div>
  );
};

export default StepCounterEl;
