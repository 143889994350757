import DateJsUtils from "@date-io/dayjs";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import da from "dayjs/locale/da";
import { FC } from "react";
import { isMobile } from "react-device-detect";
import { datePickerTheme } from "./styles.js";

export const DatePickerEl: FC<any> = ({
  selectedDate,
  disableDates,
  handleDateChange,
  handleMonthChange,
  firstAvailableDate,
}) => {
  const firstAvailableMonth = firstAvailableDate
    ? firstAvailableDate.slice(0, 7)
    : null;

  return (
    <ThemeProvider theme={datePickerTheme(selectedDate, isMobile)}>
      <MuiPickersUtilsProvider locale={da} utils={DateJsUtils}>
        <KeyboardDatePicker
          variant="static"
          format="YYYY-MM-DD"
          value={selectedDate || firstAvailableMonth}
          onChange={handleDateChange}
          shouldDisableDate={disableDates}
          disableToolbar
          disablePast
          onMonthChange={handleMonthChange}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default DatePickerEl;
