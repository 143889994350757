import dayjs from "dayjs";
import { FC, useEffect, useState, useRef } from "react";
import { EventEmitter } from "stream";
import DatePickerEl from "../Components/Date-Picker/Date-Picker";
import DividerEl from "../Components/Divider/Divider";
import { DropdownEl } from "../Components/Dropdown/Dropdown";
import LoaderEl from "../Components/Loader/loader";
import SectionWrapEl from "../Components/Section-wrapper/Section-wrapper";
import { setButtonDisabled, setCreateDummyAppointment, setSubmitState } from "../Store/actions/flow";
import { getTimeslots, setSelectedAppointmentType, setSelectedDate, setSelectedPractitioner, setSelectedTime, setTimeslot, setSelectedAppointmentLength } from "../Store/actions/timeSlots";
import { connectedStore } from "../utils/connectedStore";
import { translate } from "../utils/translationUtils";

const DateSelect: FC<any> = ({ store, dispatch }) => {
    const { timeslots, selectedDate, selectedTime, selectedTimeslot, selectedPractitioner, loading, selectedAppointmentLength } = store.timeSlots;
    const { submitState } = store.flow;
    const { selectedTreatment } = store.clinics;

   
    const [currentPage, setCurrentPage] = useState(1);
    const [hasError, setHasError] = useState(false);
    const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
    const [isCreatingDummyAppointment, setIsCreatingDummyAppointment] = useState(false);
    const [appointmentLength, setAppointmentLength] = useState(0);
    const [currentDate, setCurrentDate] = useState(new Date());
    const myRef = useRef(null)

    useEffect(() => {
        if(submitState === 'check') {
            setHasError(false);
            if(selectedTime === '' || selectedDate === '') {
                setHasError(true);
                dispatch(setSubmitState('error'));
                return;
            }
            setIsCreatingDummyAppointment(true);
            dispatch(setCreateDummyAppointment(selectedTreatment.value, {timeslot_id: selectedTimeslot, dentist_id: selectedPractitioner})).then(() => {
                setIsCreatingDummyAppointment(false);
                dispatch(setSubmitState('complete'));
            })
        } 
    }, [submitState]);

    useEffect(() => {
        dispatch(setSelectedDate(''));
        dispatch(setSelectedTime(''));
        setAvailableTimeSlots([]);
        const time:any = {};
        time.value = '';
        time.label = '';
        dispatch(setSelectedTime(time));
        if(selectedDate !== '') {
            const day = dayjs(selectedDate).format('YYYY-MM-DD');
            if(timeslots[day]) {
                let timeSlots = Object.keys(timeslots[day]) as any;
                setAvailableTimeSlots(timeSlots.map((time: any) =>{return {value: time, label: time}}));
            }
        } else {
            dispatch(getTimeslots(selectedTreatment.value, currentPage));
        }
    }, []);

    useEffect(() => {
        dispatch(getTimeslots(selectedTreatment.value, currentPage));
    }, [currentPage]);

    useEffect(() => {
        let disableButton = true;
        if(selectedDate && selectedTime && selectedTimeslot && selectedTime.value) {
            disableButton = false;
        }
        dispatch(setButtonDisabled(disableButton));
    }, [selectedDate, selectedTime, selectedTimeslot]); 

    const handleDateChange = (date: any) => {
        setAvailableTimeSlots([]);
        dispatch(setSelectedDate(null));
        const time:any = {};
        time.value = '';
        time.label = '';
        dispatch(setSelectedTime(time));
        const hasTimeSlots = !!Object.keys(timeslots).length;
    
    
        if (hasTimeSlots) {
            const element = document.getElementById('section-1');
            if (element) {
                // 👇 Will scroll smoothly to the top of the next section
                element.scrollIntoView({ behavior: 'smooth' });
              }
            const day = dayjs(date).format('YYYY-MM-DD');
            const slots = timeslots[day];
            dispatch(setSelectedDate(dayjs(date).format('YYYY-MM-DD')));
            
            if (slots) {
                const element = document.getElementById('section-1');
            if (element) {
                // 👇 Will scroll smoothly to the top of the next section
                element.scrollIntoView({ behavior: 'smooth' });
              }
                let timeSlots = Object.keys(timeslots[day]) as any;
                setAvailableTimeSlots(timeSlots.map((time: any) =>{return {value: time, label: time}}));
            }
        }
    }

    const disableDates = (date: string) => {
        
        const day = dayjs(date).format('YYYY-MM-DD');
        const isDisabled = !timeslots[day];
        return isDisabled;
    }

    const selectTimeAndPractitioner = (time: any) => {
        const activeTimeItem = timeslots[selectedDate][time.value].items[0];
        const dentistListLength = activeTimeItem.Dentists.length
        // setAppointmentLength(activeTimeItem.Duration);
        dispatch(setSelectedAppointmentType(activeTimeItem.Name));
        dispatch(setSelectedAppointmentLength(activeTimeItem.Duration))

        if(dentistListLength > 1) {
            const getPractitioner = Math.floor(Math.random() * (dentistListLength )) + 1;
            dispatch(setSelectedPractitioner(activeTimeItem.Dentists[getPractitioner].ID));
        } else {
            dispatch(setSelectedPractitioner(activeTimeItem.Dentists[0].ID));
        }
 
        const element = document.getElementById('section-1');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
          }
        dispatch(setTimeslot(activeTimeItem.ID))
        dispatch(setSelectedTime(time))
    }

    const handleMonthChange = (dateObj: any) => {
        dispatch(setSelectedDate(null));
        setAvailableTimeSlots([]);
        const time:any = {};
        time.value = '';
        time.label = '';
        dispatch(setSelectedTime(time));
   
        if(currentDate < dateObj.$d) {
            setCurrentPage(currentPage+1);
        }
        else {
            setCurrentPage(currentPage-1);
        }
        setCurrentDate(dateObj);
    
    }

    return (
    
        <SectionWrapEl>
            {isCreatingDummyAppointment || loading &&
                <div className="page--date-select--loader-wrap">
                    <div className="page--date-select--loader-text">Vi henter ledige tider <br /> - det kan tage et øjeblik</div>
                    <LoaderEl/>
                </div>
            }
            <DatePickerEl 
                selectedDate={selectedDate}
                disableDates={disableDates} 
                handleDateChange={handleDateChange}
                handleMonthChange={handleMonthChange}
            ></DatePickerEl>
            
                <>
                    <DividerEl/>
                    <p  className="page--date-select--selected_date">
                        {translate('selected_date', true)}: 
                       
                        {<span className="page--date-select--selected_date__date"> {selectedDate ? dayjs(selectedDate).locale('da').format('DD. MMM YYYY') : ''}{}</span>}
                    </p>
                    
                    <DropdownEl 
                        error={hasError && selectedTime === ''} 
                        placeholder={translate('select_time', true)}  
                        dropdownList={availableTimeSlots} 
                        selectedItem={selectedTime} 
                        setDropdownItem={selectTimeAndPractitioner}
                    />
                    <div className="page--date-select--time-info-container">
                    {selectedTime.value && 
                   
                        <>
                        
                            <p className="page--date-select--time-info">{translate('set_aside', true)}</p>
                            <b className="page--date-select--time-info">{selectedAppointmentLength} {translate('time_aside', true)}</b>
                            
                        </>
                        
                    }
                    </div>
                </>
            
           <div id="section-1"></div>
        </SectionWrapEl>
       
    );
}

export default connectedStore(DateSelect);
