import './App.scss';
import { Routes, Route, useNavigate } from "react-router-dom";
import Questions from './Pages/Questions';
import Info from './Pages/Info';
import DateSelect from './Pages/DateSelect';
import Confirm from './Pages/Confirm';
import ButtonEl from './Components/Button/Button';
import StepCounterEl from './Components/Step-counter/Step-counter';
import { contactFlow, regularFlow, simpleFlow } from './utils/flow-config';
import { translate } from './utils/translationUtils';
import Clinics from './Pages/Clinic';
import { connectedStore } from './utils/connectedStore';
import { FC, useEffect, useState } from 'react';
import { setSubmitState, setCampaignId, setStepId, setQuestions, setClinicId, setStepLength } from './Store/actions/flow';
import InfoSimple from './Pages/Info-simple';
import Complete from './Pages/Complete';
import ContactForm from './Pages/ContactForm';
import { useLocation } from "react-router-dom"
import Error from './Pages/Error';
import uuid from 'react-uuid';
import { saveClinic, searchClinics } from "./Store/actions/clinics";

const App: FC<any> = ({ store, dispatch }) => {
  const [currentStep, setCurrentStep] = useState(null) as any;
  const { stepId, flowType, disableButton, submitState, stepLength, buttonTxt } = store.flow;
  // const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate() as any;
  const search = useLocation().search;
  const { clinics, selectedTreatment, loading } = store.clinics;

  const [flow, setFlow] = useState(0);

  function renderSwitch(param: any) {
    switch(param) {
      case 'regular':
        setFlow(regularFlow.length)
        return regularFlow.filter(item => item.path === window.location.pathname)[0];
      case 'simple':
        setFlow(simpleFlow.length)
          return simpleFlow.filter(item => item.path === window.location.pathname)[0];
      case 'contact':
        setFlow(contactFlow.length)
          return contactFlow.filter(item => item.path === window.location.pathname)[0];
      default:
        return simpleFlow.filter(item => item.path === window.location.pathname)[0];
    }
  }

  useEffect(() => {
    if(window.location.pathname === '/error') {
      return;
    }
    setFlow(regularFlow.length)
    const urlSearchParams = new URLSearchParams(window.location.search);
    localStorage.setItem('sessionId', uuid());
    const campaignId = urlSearchParams.get('campaignId') ?? 1;

    const clinicId= urlSearchParams.get('clinicId');
    dispatch(setClinicId(clinicId));
    dispatch(setCampaignId(campaignId));
    dispatch(setQuestions(campaignId));
    dispatch(searchClinics(campaignId));
    dispatch(setStepLength(0));
    if(clinicId) {
      dispatch(setStepLength(1));
      
    } 
  

  }, []);

  useEffect(() => {
    if(window.location.pathname === '/error') {
      setCurrentStep('error');
      dispatch(setStepId('error'));  
      return;
    }
    const currentStepValue = renderSwitch(flowType)

    setCurrentStep(currentStepValue);
   
    dispatch(setStepId(currentStepValue.stepId));
  }, [window.location.pathname]);

  useEffect(() => {
    if(submitState === 'complete') {
      linkTo();
      dispatch(setSubmitState(''))
    }
  }, [submitState]);
  
  const linkTo = () => {
    const currentStepIndex = stepId;
    switch(flowType) {
     
      case 'regular':
        setFlow(regularFlow.length)
        navigate(regularFlow[currentStepIndex].path, {replace: false});

        break;
      case 'simple':
        setFlow(simpleFlow.length)
        navigate(simpleFlow[currentStepIndex].path, {replace: false});
        break;
      case 'contact':
        setFlow(contactFlow.length)
        navigate(contactFlow[currentStepIndex].path, {replace: false});
        break;
      default :
        setFlow(regularFlow.length)
        navigate(regularFlow[currentStepIndex].path, {replace: false});
        
    }
  }

  return (
    <div>
    <div className="App">
      {currentStep &&
      <>
        <div className="App--header">
        <img className="App--header__logo" src = "GodtSmil_logo_white_TM-01.svg" alt="Godt Smil Logo"/>
          <div>{translate('header1', true)}</div>
          <div className="App--header__bold">{translate('header2', true)}</div>
        </div>
        <div className='App--content'>
          <div className='App--content__step'>
            <Routes>
              <Route path="/" element={<Clinics/>} />
              <Route path="/questions" element={<Questions/>} />
              <Route path="/info" element={<Info/>} />
              <Route path="/ContactForm" element={<ContactForm/>} />
              <Route path="/info-simple" element={<InfoSimple/>} />
              <Route path="/date-select" element={<DateSelect/>} />
              <Route path="/confirm" element={<Confirm/>} />
              <Route path="/complete" element={<Complete/>} />
              <Route path="/error" element={<Error/>} />
            </Routes>
          </div>
          
        </div>
        <div className="App--footer">
          {currentStep.btnText &&
              <ButtonEl disabled={(submitState === 'check' || disableButton)} onClick={() => dispatch(setSubmitState('check'))} label={(currentStep.btnText)}></ButtonEl>
          }    
          <StepCounterEl 
            totalSteps={regularFlow.filter(item => item.stepId !== null).length-1} 
            currentStep={currentStep.stepId-stepLength}
          />      
        </div>
      </>
      }
    </div>
    </div>
  );
}

export default connectedStore(App);
