import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import clinics from "./reducers/clinic";
import flow from "./reducers/flow";
import patient from "./reducers/patient";
import timeSlots from "./reducers/timeSlots";

export const getReducers = (history: any) =>
  combineReducers({
    clinics,
    flow,
    timeSlots,
    patient,
    router: connectRouter(history),
  });
