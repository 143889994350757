import { createAction } from "@reduxjs/toolkit"
import { Api } from "../../api"

export const searchClinics = createAction('SEARCH_CLINICS', campaignId => {
    return {
        payload: Api.fetchClinics(campaignId)
    }
})

export const saveClinic = createAction('SAVE_CLINIC', clinic => ({
    payload: clinic,
}))

export const clearClinic = createAction('CLEAR_CLINIC', clinic => ({
    payload: clinic,
}))