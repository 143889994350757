import { forwardRef } from "react";
import { translate } from "../../utils/translationUtils";
import { Input, InputLabel, InputRequiredTag, InputWrapper } from "./styles";

const InputEl = forwardRef<HTMLInputElement, any>(
  (
    {
      autofocus = false,
      label,
      placeholder,
      value,
      setValue,
      required,
      number = false,
      error,
      length,
      type,
    },
    ref
  ) => {
    return (
      <InputWrapper>
        {label && (
          <InputLabel>
            {translate(label, true)}
            {required ? (
              <>
                <InputRequiredTag>*</InputRequiredTag>:
              </>
            ) : (
              ":"
            )}
          </InputLabel>
        )}
        <Input
          hasError={error}
          placeholder={translate(placeholder, true)}
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
          type={type}
          pattern={number ? "[0-9]*" : ""}
          autoFocus={autofocus} // Automatically focus if "autofocus" is true
          maxLength={length}
          ref={ref} // Pass the ref to the input element
        ></Input>
      </InputWrapper>
    );
  }
);

export default InputEl;
