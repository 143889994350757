import { FC, useEffect, useState } from "react";
import { translate } from "../../utils/translationUtils";
import { PrimaryBtn } from "./styles";

const ButtonEl: FC<any> = ({ label, onClick, disabled }) => {
  const [isBouncing, setIsBouncing] = useState(false);

  useEffect(() => {
    if (disabled) return;

    const interval = setInterval(() => {
      setIsBouncing(true);
      setTimeout(() => setIsBouncing(false), 2500);
    }, 7500);

    return () => clearInterval(interval);
  }, [disabled]);

  return (
    <div className="relative flex items-center justify-center">
      <PrimaryBtn
        disabled={disabled}
        onClick={onClick}
        className={isBouncing ? "animate-bounce" : ""}
      >
        {translate(label, true)}
      </PrimaryBtn>
    </div>
  );
};

export default ButtonEl;
