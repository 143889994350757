import typeToReducer from "type-to-reducer";
import {
  setAnswers,
  setButtonDisabled,
  setButtonTxt,
  setCampaignId,
  setClinicId,
  setCreateRealAppointment,
  setFlowType,
  setQuestions,
  setResetFlow,
  setStepId,
  setStepLength,
  setSubmitState,
} from "../actions/flow";
import { baseActions } from "../baseActions";

const initialState = {
  loading: false,
  disableButton: true,
  flowType: "short",
  stepLength: 0,
  stepId: 0,
  campaignId: 0,
  clinicId: null,
  submitState: "",
  questions: [],
  answers: [],
  buttonTxt: "",
};

const reducer = {
  [setStepId as any]: (state: any, { payload }: any) => ({
    ...state,
    stepId: payload,
  }),
  [setFlowType as any]: (state: any, { payload }: any) => ({
    ...state,
    flowType: payload,
  }),
  [setStepLength as any]: (state: any, { payload }: any) => ({
    ...state,
    stepLength: payload,
  }),
  [setCampaignId as any]: (state: any, { payload }: any) => ({
    ...state,
    campaignId: payload,
  }),
  [setClinicId as any]: (state: any, { payload }: any) => ({
    ...state,
    clinicId: payload,
  }),
  [setSubmitState as any]: (state: any, { payload }: any) => ({
    ...state,
    submitState: payload,
  }),
  [setButtonDisabled as any]: (state: any, { payload }: any) => ({
    ...state,
    disableButton: payload,
  }),
  [setButtonTxt as any]: (state: any, { payload }: any) => ({
    ...state,
    buttonTxt: payload,
  }),
  [setQuestions as any]: {
    ...baseActions,
    FULFILLED: (state: any, { payload }: any) => {
      return {
        ...state,
        loading: false,
        questions: payload.questions,
      };
    },
  },
  [setAnswers as any]: (state: any, { payload }: any) => ({
    ...state,
    answers: payload,
  }),
  [setCreateRealAppointment as any]: (state: any, { payload }: any) => ({
    ...state,
    answers: payload,
  }),
  [setResetFlow as any]: () => initialState,
};

export default typeToReducer(reducer as any, initialState);
