import { Checkbox, withStyles } from "@material-ui/core";
import { FC, useEffect, useRef, useState } from "react";
import { Colors } from "../colors";
import InputEl from "../Components/Input/Input";
import LoaderEl from "../Components/Loader/loader";
import SectionWrapEl from "../Components/Section-wrapper/Section-wrapper";
import { useFormState } from "../FormStateContext";
import {
  setButtonDisabled,
  setCreateRealAppointment,
  setSubmitState,
} from "../Store/actions/flow";
import { setPatient, setPhoneState } from "../Store/actions/patient";
import { connectedStore } from "../utils/connectedStore";
import { translate } from "../utils/translationUtils";

const OrangeCheckbox = withStyles({
  root: {
    color: Colors.primary,
    "&$checked": {
      color: Colors.primary,
    },
  },
  checked: {},
})(Checkbox);

const ContactForm: FC<any> = ({ store, dispatch }) => {
  const { submitState, campaignId, questions, answers } = store.flow;
  const { fullName, patientFound, cprForm } = store.patient;
  const { selectedTreatment } = store.clinics;
  const {
    selectedTime,
    selectedDate,
    selectedTimeslot,
    selectedPractitioner,
    selectedAppointmentType,
  } = store.timeSlots;

  const storedCampaignId = sessionStorage.getItem("campaignId");

  const { state, dispatch: formDispatch } = useFormState();
  const {
    firstName,
    lastName,
    streetName,
    streetNumber,
    postNumber,
    city,
    mobile,
    email,
    cpr,
    policy1,
    policy2,
  } = state.infoPage;

  const [checkingCPR, setCheckingCPR] = useState(true);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const cprRegex =
    /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])\d{2}?\s[-]?\s\d{4}$/gm;

  const firstNameRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (firstNameRef.current) firstNameRef.current.focus();
  }, []);

  // Update the form field using the context
  const setField = (type: any, value: any) => {
    formDispatch({ type, payload: value });
  };

  // Postnumber changes fetch city name
  const fetchCityByPostCode = async (postCode: string) => {
    try {
      const response = await fetch(
        `https://api.dataforsyningen.dk/postnumre?nr=${postCode}`
      );
      const data = await response.json();
      if (data.length > 0) {
        setField("SET_CITY", data[0]?.navn || "");
      } else {
        setField("SET_CITY", "");
      }
    } catch (error) {
      console.error("Error fetching city by post code:", error);
      setField("SET_CITY", "");
    }
  };

  const handlePostNumberChange = (value: string) => {
    const numericValue = value.replace(/\D/g, "");
    setField("SET_POST_NUMBER", numericValue);
    if (numericValue.length === 4) {
      fetchCityByPostCode(numericValue);
    } else {
      setField("SET_CITY", "");
    }
  };

  useEffect(() => {
    let disableButton = false;
    if (
      !policy1 ||
      !policy2 ||
      firstName === "" ||
      lastName === "" ||
      streetName === "" ||
      streetNumber === "" ||
      postNumber === "" ||
      postNumber.length !== 4 ||
      city === "" ||
      cpr === "" ||
      mobile === "" ||
      mobile.length !== 8 ||
      email === "" ||
      !email.match(emailRegex)
    ) {
      disableButton = true;
    }
    dispatch(setButtonDisabled(disableButton));
  }, [
    firstName,
    lastName,
    streetName,
    streetNumber,
    postNumber,
    city,
    cpr,
    mobile,
    email,
    policy1,
    policy2,
  ]);

  useEffect(() => {
    if (submitState === "check") {
      if (
        !policy1 ||
        !policy2 ||
        firstName === "" ||
        lastName === "" ||
        streetName === "" ||
        streetNumber === "" ||
        postNumber === "" ||
        postNumber.length !== 4 ||
        city === "" ||
        cpr === "" ||
        mobile === "" ||
        mobile.length !== 8 ||
        email === "" ||
        !email.match(emailRegex)
      ) {
        setHasError(true);
        dispatch(setSubmitState("error"));
        return;
      }

      const formularData = {
        fornavn: firstName,
        efternavn: lastName,
        adresse: `${streetName} ${streetNumber}`,
        postnummer: postNumber,
        by: city,
        telefonnummer: mobile,
        email: email,
      };

      sessionStorage.setItem("formularData", JSON.stringify(formularData));

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "confirm_booking",
        step_name: "contactForm",
        campaignId: storedCampaignId || "default_campaign",
        current_step: "5",
        total_steps: "5",
        clinic: selectedTreatment?.label || "Unknown Clinic",
        questions: questions.map((q: any, index: number) => ({
          question: q.question,
          answer: answers[index] === 1 ? "ja" : "nej",
        })),
        chosen_date: selectedDate
          ? selectedDate.split("-").reverse().join("")
          : null,
        chosen_time: selectedTime?.value || null,
        formular: formularData,
      });

      const patientObj = {
        FirstName: firstName,
        LastName: lastName,
        Phone: mobile,
        StreetName: streetName,
        StreetNumber: streetNumber,
        City: city,
        ZipCode: postNumber,
        Email: email,
        Cpr: cpr.replace(/ /g, ""),
        campaign_id: storedCampaignId,
      };

      dispatch(setPatient(selectedTreatment.clinicId, patientObj)).then(() => {
        createRealAppointment();
        dispatch(setSubmitState("complete"));
        dispatch(setPhoneState(mobile));
      });
    }
  }, [submitState]);

  const createRealAppointment = () => {
    const appointmentObj = {
      timeslot_id: selectedTimeslot,
      dentist_id: selectedPractitioner,
      patient_id: cpr.replace(/ /g, ""),
    };
    dispatch(setCreateRealAppointment(selectedTreatment.value, appointmentObj));
  };

  const formatPhoneNumber = (value: string) => {
    const rawValue = value.replace(/\D/g, "");
    return rawValue.replace(/(\d{2})(?=\d)/g, "$1 ").trim();
  };

  return (
    <div>
      {loading && (
        <div className="page--date-select--loader-wrap">
          <LoaderEl />
        </div>
      )}

      <SectionWrapEl>
        <InputEl
          autofucus
          ref={firstNameRef}
          error={hasError && firstName === ""}
          label={"firstName"}
          placeholder={"firstName"}
          value={firstName}
          setValue={(val: any) => setField("SET_FIRST_NAME", val)}
          required
        />
        <InputEl
          error={hasError && lastName === ""}
          label={"lastName"}
          placeholder={"lastName"}
          value={lastName}
          setValue={(val: any) => setField("SET_LAST_NAME", val)}
          required
        />

        <InputEl
          error={hasError && streetName === ""}
          label={"address"}
          placeholder={"streetName"}
          value={streetName}
          setValue={(val: any) => setField("SET_STREET_NAME", val)}
          required
        />
        <InputEl
          type={"tel"}
          error={hasError && streetNumber === ""}
          placeholder={"number"}
          value={streetNumber}
          setValue={(val: any) => setField("SET_STREET_NUMBER", val)}
          required
        />
        <InputEl
          type={"tel"}
          error={hasError && (postNumber === "" || postNumber.length !== 4)}
          placeholder={"postNumber"}
          value={postNumber}
          length={4}
          setValue={handlePostNumberChange}
          required
        />
        <InputEl
          error={hasError && city === ""}
          placeholder={"city"}
          value={city}
          setValue={(val: any) => setField("SET_CITY", val)}
          required
        />

        <InputEl
          type={"tel"}
          error={hasError && (cpr === "" || !cpr.match(cprRegex))}
          label={"cprNumber"}
          placeholder={"XXXXXX - XXXX"}
          value={cpr}
          setValue={(val: any) => setField("SET_CPR", val)}
          required
        />

        <InputEl
          type={"tel"}
          error={hasError && (mobile === "" || mobile.length !== 8)}
          label={"phoneNumber"}
          placeholder={"phoneNumber"}
          value={formatPhoneNumber(mobile)}
          length={11}
          setValue={(val: string) => {
            const rawValue = val.replace(/\D/g, "");
            setField("SET_MOBILE", rawValue.slice(0, 8));
          }}
          required
        />

        <InputEl
          type={"email"}
          error={hasError && (email === "" || !email.match(emailRegex))}
          label={"email"}
          placeholder={"email"}
          value={email}
          setValue={(val: any) => setField("SET_EMAIL", val)}
          required
        />

        <div className="page--info-simple--policy text-base">
          <OrangeCheckbox
            onChange={() => setField("SET_POLICY_1", !policy1)}
            checked={policy1}
          />
          <p
            className={
              hasError && !policy1 ? "page--info-simple--policy--error" : ""
            }
          >
            <span>{translate("Jeg bekræfter at have læst", true)}</span>{" "}
            <a
              href="https://www.godtsmil.dk/privatlivspolitik/"
              className="text-blue-600"
              target="_blank"
            >
              {translate("Godt Smil privatlivspolitik.", true)}
            </a>
          </p>
        </div>

        <div className="page--info-simple--policy text-base">
          <OrangeCheckbox
            onChange={() => setField("SET_POLICY_2", !policy2)}
            checked={policy2}
          />
          <p
            className={
              hasError && !policy2 ? "page--info-simple--policy--error" : ""
            }
          >
            <span>{translate("Jeg bekræfter at have læst", true)}</span>{" "}
            <a
              href="https://www.godtsmil.dk/velkomst-info/"
              className="text-blue-600"
              target="_blank"
            >
              {translate("Godt Smils betalings- og udeblivelsespolitik.", true)}
            </a>
          </p>
        </div>
      </SectionWrapEl>
    </div>
  );
};

export default connectedStore(ContactForm);
