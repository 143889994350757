import { baseActions } from '../baseActions'
import typeToReducer from 'type-to-reducer'
import { searchPatient, setEmailState, setCPRState, setPhoneState } from '../actions/patient';

const initialState = {
    loading: false,
    cprForm: '',
    patientFound: false,
    fullName: '',
    email:'',
    phoneState:'',
    patient: {
        firstname: '',
        lastname: '',
        address: '',
        city: '',
        zip: '',
        phone: '',
        email: '',
        cpr: ''
    }
};

const reducer = {
    [searchPatient as any]: {
        ...baseActions,
        FULFILLED: (state: any, { payload }: any) => {
            return {
                ...state,
                ...payload.data,
                loading: false,
                patientFound: payload.result === 'patient',
                fullName: payload.result === 'patient' ? payload.name : ''
            }
        },
    },
    [setEmailState as any]: (state: any, { payload }: any) => ({
        ...state,
        email: payload,
    }),
    [setCPRState as any]: (state: any, { payload }: any) => ({
        ...state,
        cprForm: payload,
    }),
    [setPhoneState as any]: (state: any, { payload }: any) => ({
        ...state,
        phoneState: payload,
    }),
};

export default typeToReducer(reducer, initialState);