import styled from 'styled-components'
import { Colors } from '../../colors'

const StepCounter = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed ${Colors.border};
    margin: .5rem 1rem
`

const CountItem = styled.div`
    color: ${Colors.white};
    height: 2rem;
    width: 2rem;
    background-color: ${({ active }) => (active ? Colors.primary : Colors.border)};
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -1rem;
    position: relative
`


export {
    StepCounter,
    CountItem
}